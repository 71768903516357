import { CHAT_ACTIONS } from "./dashboard.constants";
import { GetDashboardStats } from "./dashboard.service";
import toast, { Toaster } from "react-hot-toast";

export const getDashboardStats = () => {
  return async (dispatch: any) => {
    dispatch({ type: CHAT_ACTIONS.DASHBOARD_STATS_PROCESS});
    try {
      const data:any = await GetDashboardStats();
      return dispatch({ type: CHAT_ACTIONS.DASHBOARD_STATS_SUCCESS , payload:data });
    } catch (error: any) {
      console.error(error.message);
      toast.error(error.response.data.error);
      return dispatch({
        type: CHAT_ACTIONS.DASHBOARD_STATS_FAILED,
        payload: error.message,
      });
    }
  };
};