import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatGptIcon from "../assets/chat-gpt.png";
import { connect } from "react-redux";
import { forgetUserPassword } from "../app/auth/auth.action";
import { useToast } from '@chakra-ui/react'
import { ClipLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';  // Import Toastify CSS
import { checkAutoLogin } from "app/user/user.service";

type ForgotPasswordPayload = {
  forgetUserPassword: (email: string) => any;
  loading: boolean;
  error: string;
  success: boolean;
};

const ForgotPass = ({ forgetUserPassword, loading, error, success }: ForgotPasswordPayload) => {
  const [email, setEmail] = useState<string>("");

  const toast = useToast();
  const navigate = useNavigate()

  useEffect(() => {
    if (success) {
      toast({
        description: "Password reset email sent successfully!",
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }
    if (error) {
      toast({
        description: `Password reset failed: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }, [success, error]);

  const handleForgotPassword = () => {
    if (email) {
      forgetUserPassword(email);
    }
  };

  useEffect(() => {
    if(checkAutoLogin()){
      navigate("/chat")
    }
  },[])

  return (
    <>
      <section className="flex items-center justify-center">
        <div className="h-screen flex flex-col items-center justify-center md:w-[30%] sm:w-[50%] w-[90%]">
          <img src={ChatGptIcon} width={50} alt="gpt-icon" />
          <h1 className="lg:text-3xl text-2xl font-bold my-4">Forgot Password</h1>
          <div className="flex flex-col w-full">
            <input
              placeholder="Email Address *"
              className="border border-blue-500 rounded placeholder:text-base px-5 py-3 my-3 outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            className="bg-blue-500 w-full rounded-lg p-2 my-5 text-white sm:text-[18px] text-base"
            onClick={handleForgotPassword}
            disabled={loading}  // Disable button while loading
          >
            {loading ? <ClipLoader color="white" size={25}/> : "Send"}
          </button>

        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  forgetUserPassword: (email: string) => {
    dispatch(forgetUserPassword(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
