import { STRIPE_ACTIONS } from "./stripe.constants";

const INITIAL_STATE = {
    loading: false,
    success: true,
    data: ''
}

export const stripe = (state:any = INITIAL_STATE , action:any) => {
    switch(action.type){

        case STRIPE_ACTIONS.GETTING_PLANS:
            return{
                ...state,
                loading : true,
                success: false,
            }
        
        case STRIPE_ACTIONS.GET_PLANS:
            return{
                ...state,
                loading : false,
                success: true,
                data: action.payload
            }

        case STRIPE_ACTIONS.GET_PLANS_FAILED:
            return{
                ...state,
                loading : false,
                success: false,
                data: ''
            }
        
        case STRIPE_ACTIONS.SESSION_CHECKOUT_PROCESS:
            return{
                ...state,
                loading : true,
                success: false,
            }
        
        case STRIPE_ACTIONS.SESSION_CHECKOUT:
            return{
                ...state,
                loading : false,
                success: true,
            }
        case STRIPE_ACTIONS.SESSION_CHECKOUT_FAILED:
            return{
                ...state,
                loading : false,
                success: false,
            }
        case STRIPE_ACTIONS.SESSION_PROCESS:
            return{
                ...state,
                loading : true,
                success: false,
            }
        
        case STRIPE_ACTIONS.SESSION:
            return{
                ...state,
                loading : false,
                success: true,
            }
        case STRIPE_ACTIONS.SESSION_FAILED:
            return{
                ...state,
                loading : false,
                success: false,
            }

        default:
            return state;
    }
}