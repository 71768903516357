import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { STRIPE_ENDPOINTS } from "./stripe.constants";

export const getPlans = async () => {
    try {
      const response = await makeRequest(
        STRIPE_ENDPOINTS.GET_PLANS,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in getting plans!`);
      }
      return response.data;
    } catch (error) {
      console.error(`get plans > ${error.toString()}`);
      throw error;
    }
  };

export const checkoutSession = async (priceId:any) => {
    try {
      const response = await makeRequest(
        STRIPE_ENDPOINTS.CHECKOUT_SESSION+"?priceId="+priceId,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in checkout session!`);
      }
      return response.data;
    } catch (error) {
      console.error(`checkout session > ${error.toString()}`);
      throw error;
    }
  };

  export const session = async () => {
    try {
      const response = await makeRequest(
        STRIPE_ENDPOINTS.SESSION,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in sessions!`);
      }
      return response.data;
    } catch (error) {
      console.error(`sessions > ${error.toString()}`);
      throw error;
    }
  };