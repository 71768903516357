import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    InputGroup,
    Input,
    InputRightElement,
    Button,
    useDisclosure
} from '@chakra-ui/react';

import { useToast } from '@chakra-ui/react'
import { ClipLoader } from "react-spinners";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import liveLogo from '../assets/live100_logo.svg';
import GoogleIcon from '../assets/Google.svg';
import { AiFillFacebook } from "react-icons/ai";
import { Link } from "react-router-dom";
import { loginuser } from 'app/auth/auth.action';
import ForgetPassModal from './ForgetPassModal';
import { googleAuth } from 'app/auth/auth.action';
import { fbAuth } from 'app/auth/auth.action';
import OtpModal from './OtpModal';
import { GetUser } from 'app/user/user.action';

interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSignupClick: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ isOpen, onClose, onSignupClick }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isforgetPass, setIsForgetPass] = useState<boolean>(false);
    const [isOtpOpen, setIsOtpOpen] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);
    const User = useSelector((state:any) => state.user);


    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const handleForgotPass = () => {
        onClose();
        setIsForgetPass(true)
    }

    const handleLogin = async () => {
        if (!email || !password) {
            toast({
                description: "Please enter all the details!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }
        else {
            try {
                const loginData = await dispatch(loginuser(email, password) as any);
                if (loginData?.accessToken) {
                    toast({
                        description: "User Login Successfully",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    await dispatch(GetUser() as any);
                    if (User?.user?.isonboarded) {
                        navigate("/user/dashboard")
                    }
                    else if (!(User?.user?.isonboarded)) { navigate("/onboard") }
                }
                else if (loginData?.response?.data?.error === "Email is not verified, Please verify your email!") {
                    onClose();
                    toast({
                        description: "OTP has been sent to your account!",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    setIsOtpOpen(true);
                }
                else {
                    toast({
                        description: loginData?.response?.data?.error,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            }
            catch (error: any) {
                toast({
                    description: error?.response?.data?.error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }
    }

    const handleGoogleAuth = async () => {
        try {
            const result = await dispatch(googleAuth() as any)
            if (result.startsWith('http')) {
                window.location.href = result;
            }
        }
        catch (error: any) {

        }
    }

    const handleFbAuth = async () => {
        try {
            const result = await dispatch(fbAuth() as any)
            if (result.startsWith('http')) {
                window.location.href = result;
            }
        }
        catch (error: any) {

        }
    }

    return (
        <>
            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent marginTop="40px" borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" overflow="hidden">
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center" >
                                <h1 className="text-[32px] font-semibold my-5" >Member Login</h1>

                                <div className="flex flex-col w-[70%]" >
                                    <input type="email" placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} className="border-[1px] border-[#E2E2E2] rounded-[9px] px-3 my-3 h-[53px]" />
                                    <InputGroup size='md' border="1px solid #E2E2E2" borderRadius="9px" >
                                        <Input
                                           padding="0px 10px"
                                            type={show ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            border="none"
                                            onChange={(e) => setPassword(e.target.value)}
                                            height="53px"
                                        />
                                        <InputRightElement width='2.5rem'>
                                            <Button bg="#fff" _hover={{ bg: "#fff" }} h='1.75rem' marginTop="12px" size='sm' onClick={handleClick}>
                                                {show ? <AiFillEyeInvisible className='text-[17px] text-[#696969]' /> : <AiFillEye className='text-[17px]' />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <p onClick={handleForgotPass} className="font-medium my-4 cursor-pointer" >Forgot password?</p>
                                    <Button onClick={handleLogin} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="12px" marginBottom="14px" color="#fff" padding="13px 20px" className="font-semibold" height="50px">
                                        {
                                            AuthData.loading ?
                                                <ClipLoader color="white" size={25} />
                                                :
                                                "Log In"
                                        }
                                    </Button>
                                    <p className='text-center text-[#000]' >or</p>

                                    <Button onClick={handleGoogleAuth} display="flex" justifyContent="justify-between" alignItems="center" bg="#fff" borderRadius="12px" margin="14px 0" color="#000" padding="13px 20px" className="font-semibold" border="1px solid #E2E2E2" height="53px">
                                        {
                                            AuthData.GLoading ?
                                                <ClipLoader color="#8a8a8a" size={25} />
                                                :
                                                <>
                                                    <img src={GoogleIcon} alt="google" width={"5%"} />
                                                    <p className='w-[90%]' >
                                                        Continue with Google
                                                    </p>
                                                </>
                                        }
                                    </Button>
                                    <Button onClick={handleFbAuth} display="flex" justifyContent="justify-between" alignItems="center" bg="#4267B2" _hover={{ bg: "#4267B2" }} borderRadius="12px" margin="14px 0" color="#fff" padding="13px 20px" className="font-semibold" height="53px" >
                                        {
                                            AuthData.FBLoading ?
                                                <ClipLoader color="#8a8a8a" size={25} />
                                                :
                                                <>
                                                    <AiFillFacebook className='w-[6%] text-[20px]' />
                                                    <p className='w-[90%]'>
                                                        Continue with Facebook
                                                    </p>
                                                </>
                                        }
                                    </Button>


                                    <div className="flex justify-center text-[#000] mb-5" >
                                        <p className='my-3 text-[#696969] flex' >Not a member yet? <p onClick={onSignupClick} className="font-semibold text-black cursor-pointer mx-1" >  Sign up now!</p></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <ForgetPassModal isOpen={isforgetPass} onClose={() => setIsForgetPass(false)} />
            <OtpModal isOpen={isOtpOpen} onClose={() => setIsOtpOpen(false)} email={email} />
        </>

    );
};

export default LoginModal;
