import React from 'react';
import { useState , useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    InputGroup,
    Input,
    InputRightElement,
    Button,
    useDisclosure
} from '@chakra-ui/react';
import CheckMark from '../assets/Check Mark.png'
import CancelMark from '../assets/Cancel.png'
import liveLogo from '../assets/live100_logo.svg';
import { CheckoutSession, GetPlans } from 'app/stripe/stripe.action';
import { ClipLoader } from "react-spinners";



interface PlanModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const PlanTableModal: React.FC<PlanModalProps> = ({ isOpen, onClose }) => {

    const [isYearly, setIsYearly] = useState(false);
    const [priceId , setPriceId] = useState('');

    const dispatch = useDispatch();
    const Stripe = useSelector((state:any) => state.stripe);

    const tableData = [
        { feature: "Progress Tracking", free: true, premium: true },
        { feature: "Personalized Nutrition Plan", free: false, premium: true },
        { feature: "Exercise Recommendations", free: false, premium: true },
        { feature: "Mindfulness Practices", free: false, premium: true },
        { feature: "Custom Fitness Routine", free: false, premium: true },
        { feature: "Mental Wellness Program", free: false, premium: true },
        { feature: "Conversations with AI Agents", free: false, premium: true }
    ]

    const handlePlan = async () => {
        console.log("PRICE: " , priceId)
        try{
            const result = await dispatch(CheckoutSession(priceId) as any)
            if(result.payload.includes("https")){
                window.location.href = result.payload
            }
            console.log("RESULT:" , result.payload)
        }
        catch(error){
            console.log("ERROR: " , error)
        }
    }

    useEffect(() => {
        if(isYearly){
            Stripe?.data?.data?.map((elem:any) => {
                if(elem.recurring.interval === 'year'){
                    setPriceId(elem.id)
                }
            })
        }
        if(!isYearly){
            Stripe?.data?.data?.map((elem:any) => {
                if(elem.recurring.interval === 'month'){
                    setPriceId(elem.id)
                }
            })
        }
    },[isYearly , Stripe.data])

    useEffect(() => {
        dispatch(GetPlans() as any);    
    },[])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="1020px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center" >
                                <h1 className="text-[35px] font-bold my-5 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Advantages of Becoming a Premium Member</h1>
                            </div>
                            <div className="max-w-5xl mx-auto my-10">
                                <table className="w-full text-left border-collapse border rounded-lg overflow-hidden shadow-lg">
                                    <thead className="bg-[#336500] text-white">
                                        <tr>
                                            <th className="p-4 text-start">Features</th>
                                            <th className="p-4 text-center">Free</th>
                                            <th className="p-4 text-center">Premium</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white text-center">
                                        {/* Feature Rows */}
                                        {tableData.map((row, idx) => (
                                            <tr key={idx} className="border-t">
                                                <td className="p-4 text-left border">{row.feature}</td>
                                                <td className="p-4 border text-center">
                                                    {row.free ? (
                                                        <img src={CheckMark} alt="" className="mx-auto"/>
                                                    ) : (
                                                       <img src={CancelMark} alt="" className="mx-auto" />
                                                    )}
                                                </td>
                                                <td className="p-4 border">
                                                    {row.premium ? (
                                                        <img src={CheckMark} alt="" className="mx-auto" />
                                                    ) : (
                                                    <img src={CancelMark} alt="" className="mx-auto" />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        {/* Toggle Row */}
                                        <tr className="border-t">
                                            <td className="p-4 border text-start">
                                                <div className="inline-block border-2 rounded-[30px] px-2">
                                                    <button
                                                        className={`px-4 py-2 my-1 rounded-[16px] ${!isYearly ? "bg-gradient-to-r from-[#507C37] to-[#232323] text-white" : ""
                                                            }`}
                                                        onClick={() => setIsYearly(false)}
                                                    >
                                                        Monthly
                                                    </button>
                                                    <button
                                                        className={`px-4 py-2 my-1 rounded-[16px] ${isYearly ? "bg-gradient-to-r from-[#507C37] to-[#232323] text-white" : ""
                                                            }`}
                                                        onClick={() => setIsYearly(true)}
                                                    >
                                                        Yearly
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="p-4 border font-semibold">
                                                <small className='text-[17px]'>$</small>
                                                <small className='text-[30px]'>0</small>
                                            </td>
                                            <td className="p-4 text-2xl border font-semibold">
                                                {
                                                isYearly ?
                                                <>
                                                <small className='text-[17px]'>$</small>
                                                <small className='text-[30px]'>144</small>
                                                </>
                                                :
                                                <>
                                                <small className='text-[17px]'>$</small>
                                                <small className='text-[30px]'>14.99</small>
                                                </>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* Get Started Button */}
                                <div className="flex justify-end mt-3 mr-10">
                                    <button onClick={handlePlan} className="bg-[#54A106] text-white px-6 py-2 rounded-[10px] text-lg font-semibold shadow-lg hover:bg-green-600">
                                        {
                                            Stripe.loading?
                                            <ClipLoader color="white" size={25} />
                                            :
                                            "Get Started"
                                        }
                                    </button>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>

    );
};

export default PlanTableModal;
