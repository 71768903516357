import React from "react";
import { Navigate } from "react-router-dom";
import { checkAutoLogin } from "app/user/user.service";

const ProtectedRoute = (Element:any) => {
  return (props:any) => {
    const isLoggedIn = checkAutoLogin();

    if (!isLoggedIn) {
      return <Navigate to="/"/>;
    }
    return <Element {...props} />;
  };
};

export default ProtectedRoute;
