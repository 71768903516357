import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Filler, Tooltip, Legend);

const LineChart = () => {
  // Gradient background
  const createGradient = (ctx: CanvasRenderingContext2D, chartArea: any) => {
    const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(1, '#507C37');
    gradient.addColorStop(0, '#232323');
    return gradient;
  };

  const data = {
    labels: ['', '', '', '', '', '', ''], // Empty labels for a smoother look
    datasets: [
      {
        label: 'Weekly',
        data: [10, 30, 20, 40, 35, 50, 75], // Your data points
        borderColor: (context: any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
  
            if (!chartArea) {
              return null;
            }
            return createGradient(ctx, chartArea); // Gradient for the line border
          },
        borderWidth: 5,
        fill: true, // Enable fill under the line
        backgroundColor: "transparent",
        tension: 0.4, // Curve tension to smooth the line
        pointRadius: 0, // Remove points from the line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false, // Hide the x-axis labels
        grid: {
          display: false, // Remove x-axis grid lines
        },
      },
      y: {
        display: false, // Hide the y-axis labels
        grid: {
          display: false, // Remove y-axis grid lines
        },
        suggestedMin: 0, // Minimum value for y-axis
        suggestedMax: 80, // Maximum value for y-axis
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips for a cleaner look
      },
    },
  };

  return (
    <div style={{ position: 'relative', width: '300px', height: '150px' , padding:"10px" }}>
      <Line data={data} options={options} />
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          fontSize: '24px',
          fontWeight: 'bold',
        }}
        className='bg-gradient-to-r from-[#507C37] to-[#232323] bg-clip-text text-transparent'
      >
        75
      </div>
    </div>
  );
};

export default LineChart;