export const STRIPE_ACTIONS = {
    GETTING_PLANS: "GETTING_PLANS",
    GET_PLANS: "GET_PLANS",
    GET_PLANS_FAILED : "GET_PLANS_FAILED",
    SESSION_CHECKOUT_PROCESS : "SESSION_CHECKOUT_PROCESS",
    SESSION_CHECKOUT : "SESSION_CHECKOUT",
    SESSION_CHECKOUT_FAILED : "SESSION_CHECKOUT_FAILED ",
    SESSION_PROCESS: "SESSION_PROCESS",
    SESSION : "SESSION",
    SESSION_FAILED: "SESSION_FAILED"
}
export const STRIPE_ENDPOINTS = {
    GET_PLANS: "stripe/get-plans",
    SESSION : "stripe/session",
    CHECKOUT_SESSION: "stripe/checkout-session"
}