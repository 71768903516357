import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    InputGroup,
    Input,
    InputRightElement,
} from '@chakra-ui/react';

import { useToast } from '@chakra-ui/react'
import { ClipLoader } from "react-spinners";
import liveLogo from '../assets/live100_logo.svg';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { forgetUserPassword, resendOtp, resetUserPassword, verifyEmail } from 'app/auth/auth.action';
import VerifyCodeModal from './VerifyCodeModal';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ResetPassModal: React.FC<SignupModalProps> = ({ isOpen, onClose}) => {

    const [password , setPassword] = useState('');
    const [confirmPass , setConfirmPass] = useState('');
    const [show, setShow] = React.useState(false);

    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);

    const handleClick = () => setShow(!show);

    const handleResetPass = async () => {
        const result = await dispatch(resetUserPassword(password) as any);
        if(result?.success){
            onClose();
            toast({
                description: result?.data?.message,
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
            navigate("/user/dashboard")
        }
        else{
            onClose()
            toast({
                description: result?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
        }
    }


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center w-full" >
                                <h1 className="text-[32px] font-semibold my-3 w-full text-center" >Reset Password</h1>
                                <div className="flex flex-col w-[90%]" >
                                    <div className='my-2 flex flex-col items-center' >
                                    <InputGroup size='md' border="2px solid #E2E2E2" borderRadius="8px" my={3} >
                                    <Input
                                        pr='4.5rem'
                                        type={show ? 'text' : 'password'}
                                        placeholder='Enter password'
                                        border="none"
                                        onChange={(e:any) => setPassword(e.target.value)}
                                    />
                                    <InputRightElement width='4.5rem'>
                                        <Button bg="#fff" _hover={{ bg: "#fff" }} h='1.75rem' size='sm' onClick={handleClick}>
                                            {show ? <AiFillEyeInvisible className='text-[17px]' /> : <AiFillEye className='text-[17px]' />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                               
                                <InputGroup size='md' border="2px solid #E2E2E2" borderRadius="8px" my={3} >
                                    <Input
                                        pr='4.5rem'
                                        type={show ? 'text' : 'password'}
                                        placeholder='confirm password'
                                        border="none"
                                        onChange={(e:any) => setConfirmPass(e.target.value)}
                                    />
                                    <InputRightElement width='4.5rem'>
                                        <Button bg="#fff" _hover={{ bg: "#fff" }} h='1.75rem' size='sm' onClick={handleClick}>
                                            {show ? <AiFillEyeInvisible className='text-[17px]' /> : <AiFillEye className='text-[17px]' />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup><Button onClick={handleResetPass} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="8px" color="#fff" margin="13px 0" padding="13px 40px" className="font-semibold" >
                                            {
                                                AuthData.loading ?
                                                    <ClipLoader color="white" size={25} />
                                                    :
                                                    "Send"
                                            }
                                        </Button>
                                        </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ResetPassModal;
