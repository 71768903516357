import { CHAT_ACTIONS } from "./chats.constants";

const INITIAL_STATE = {
    chats: "",
    loading: false,
    success: false,
    chatList : [] as any,
    conversation: [] as any,
    agents: "",
    agentName: "",
    agentIcon: "",
    assistant : "",
    createChatLoader: "",
}

export const chats = (state:any = INITIAL_STATE , action:any) => {
    switch(action.type) {

        case CHAT_ACTIONS.CREATE_CHAT:
            return{
                ...state,
                createChatLoader: true
            }
        case CHAT_ACTIONS.CHAT_CREATED:
            return{
                ...state,
                chats:action.payload,
                createChatLoader: false,
                success: true,
            }

        case CHAT_ACTIONS.CREATE_CHAT_FAILED:
            return{
                ...state,
                createChatLoader: false,
                success: false,
                chats:action.payload,
            }

        case CHAT_ACTIONS.GETTING_ALL_CHATS : 
        return {
            ...state,
            loading : true,
            success : false,
        }

        case CHAT_ACTIONS.GET_ALL_CHATS : 
            return{
                ...state,
                loading : false,
                success: true,
                chatList : action.payload
            }
       
            case CHAT_ACTIONS.GET_ALL_CHATS_FAILED : 
            return{
                ...state,
                loading : false,
                success: false,
                chatList : action.payload
            }

            case CHAT_ACTIONS.DELETING_CHAT : 
                return{
                    ...state,
                    loading: true,
                    success: false
                }
            
            case CHAT_ACTIONS.DELETE_CHAT : 
                return{
                    ...state,
                    chats: action.payload,
                    loading: false,
                    success: true
                }
            
                
            case CHAT_ACTIONS.DELETE_CHAT_FAILED : 
                    return{
                        ...state,
                        chats: null,
                        loading: false,
                        success: false
                    }

            case CHAT_ACTIONS.UPDATING_CHAT_NAME:
                        return{
                            ...state,
                            loading: true,
                            success: false
                        }

            case CHAT_ACTIONS.UPDATED_CHAT_NAME:
                        return{
                            ...state,
                            chat : null,
                            loading: false,
                            success: true
                        }

            case CHAT_ACTIONS.UPDATE_CHAT_NAME_FAILED:
                        return{
                            ...state,
                            chat : action.payload,
                            loading: false,
                            success: false
                        }
            
            case CHAT_ACTIONS.CONVERSATIONS:
                return{
                    ...state,
                    loading: true,
                    success: false
                }
           
            case CHAT_ACTIONS.CONVERSATIONS_SUCCESSSFULL:
                return{
                    ...state,
                    loading: false,
                    success: true,
                    conversation : action.payload
                }
            case CHAT_ACTIONS.CONVERSATIONS_FAILED:
                return{
                    ...state,
                    loading: false,
                    success: false,
                    conversation : [""]
                }

            case CHAT_ACTIONS.SET_LOCAL_CONVERSATIONS:
                return{
                    ...state,
                    conversation: action.payload    
                }
            
                case CHAT_ACTIONS.GETTING_ALL_AGENTS : 
                return {
                    ...state,
                    loading : true,
                    success : false,
                }
        
                case CHAT_ACTIONS.GET_ALL_AGENTS :
                    return{
                        ...state,
                        loading : false,
                        success: true,
                        agents : action.payload,
                    }
               
                    case CHAT_ACTIONS.GET_ALL_AGENTS_FAILED : 
                    return{
                        ...state,
                        loading : false,
                        success: false,
                        agents : action.payload
                    }
                    
                    case CHAT_ACTIONS.SET_AGENT_NAME: 
                    return{
                        ...state,
                        agentName : action.payload.data,
                        agentIcon : action.payload.icon,

                    }
                    case CHAT_ACTIONS.UPDATING_AGENT: 
                    return{
                        ...state,
                        loading : true,
                        success: false

                    }
                    case CHAT_ACTIONS.UPDATED_AGENT: 
                    return{
                        ...state,
                        loading : false,
                        success: true,
                        agents : action.payload
                    }
                    case CHAT_ACTIONS.UPDATE_AGENT_FAILED: 
                    return{
                        ...state,
                        loading : false,
                        success: false,
                        agents : null
                    }
                    case CHAT_ACTIONS.GETTING_ASSISTANT: 
                    return{
                        ...state,
                        loading : true,
                        success: false,
                    }
                    case CHAT_ACTIONS.GET_ASSISTANT: 
                    return{
                        ...state,
                        loading : false,
                        success: true,
                        assistant : action.payload
                    }
                    case CHAT_ACTIONS.GET_ASSISTANT_FAILED: 
                    return{
                        ...state,
                        loading : false,
                        success: false,
                        assistant : null
                    }

                case CHAT_ACTIONS.CHAT_CONVERSATIONS:
                return{
                    ...state,
                    loading: true,
                    success: false
                }
           
            case CHAT_ACTIONS.CHATS_CONVERSATIONS_SUCCESSSFULL:
                return{
                    ...state,
                    loading: false,
                    success: true,
                }
            case CHAT_ACTIONS.CHATS_CONVERSATIONS_FAILED:
                return{
                    ...state,
                    loading: false,
                    success: false,
                }
                case CHAT_ACTIONS.DELETING_FILE:
                return{
                    ...state,
                    loading: true,
                    success: false
                }
           
            case CHAT_ACTIONS.DELETE_FILE_SUCCESSSFULL:
                return{
                    ...state,
                    loading: false,
                    success: true,
                }
            case CHAT_ACTIONS.DELETE_FILE_FAILED:
                return{
                    ...state,
                    loading: false,
                    success: false,
                }
            
            default:
                return state;
    }
}