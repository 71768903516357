import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserNavbar from "./UserNavbar";
import WaterGlass from '../assets/water-glass.png'
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { MdAdd } from "react-icons/md";
import { MdCheck } from "react-icons/md";
import { MdOutlineCreate } from "react-icons/md";
import PremiumImg from '../assets/premium.png'
import { FaPen } from "react-icons/fa";
import { MdOutlinePrint } from "react-icons/md";

const ExerciseDiary = () => {
    return (
        <>
            <div className="bg-[#F5F5F5]" >
                <UserNavbar />

                {/* food diary */}
                <section className="sm:py-5 md:py-4 px-5 md:px-20 w-full flex flex-col md:flex-row justify-between my-3">
                    <div className="w-[70%]">
                        <div className="flex items-center" >
                            <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent mr-5 font-semibold" >Your Exercise Diary For</p>
                            <div className="flex items-center" >
                                <div className="mr-2" >
                                    <MdOutlineArrowBackIos className="bg-gradient-to-r from-[#507C37] to-[#232323] text-[25px] text-white p-1" />
                                </div>
                                <p className="bg-gradient-to-r from-[#507C37] to-[#232323] text-white px-2" >date</p>
                                <div className="ml-2" >
                                    <MdArrowForwardIos className="bg-gradient-to-r from-[#507C37] to-[#232323] text-[25px] text-white p-1" />
                                </div>
                            </div>
                        </div>
                        <p className="w-full h-[8px] bg-[#D9D9D9] my-1"></p>

                        <div className="flex justify-between w-[100%] mt-3 pb-2 border-b-[3px] border-[#D9D9D9]">

                            <div className="my-2 font-semibold" >
                                <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Cardiovascular</p>
                                <div className="flex items-center" >
                                    <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">Add Exercie</small>
                                    <small className="mx-4 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">Quick Tools</small>
                                </div>
                            </div>

                            <div className="w-[45%]" >
                                <table className="w-full border-separate border-spacing-1">
                                    <thead className="w-full" >
                                        <tr>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] font-medium text-white w-[100px] py-2" >
                                                <div>
                                                    <p>Minutes</p>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[100px] py-2" >
                                                <div>
                                                    <p>Calories Burned</p>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                        <div className="flex flex-col items-end w-[100%] my-5 py-10">
                            <div className="w-[45%]" >
                                <table className="w-full border-separate border-spacing-1">
                                    <tbody className="w-full" >
                                        <tr>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                            <td className="bg-[#D9D9D9]" >
                                                <div className="border-b-2 border-[#C2C2C2] bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">
                                                    hi
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="flex justify-between w-[100%] mt-10 pb-2 border-b-[3px] border-[#D9D9D9]">

                            <div className="my-2 font-semibold" >
                                <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Strength Training</p>
                                <div className="flex items-center" >
                                    <small className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Add Exercise</small>
                                    <small className="mx-4 bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent">Quick Tools</small>
                                </div>
                            </div>

                            <div className="w-[55%]" >
                                <table className="w-full border-separate border-spacing-1">
                                    <thead className="w-full" >
                                        <tr>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] font-medium text-white w-[100px] py-2" >
                                                <div>
                                                    <p>Sets</p>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[100px] py-2" >
                                                <div>
                                                    <p>Reps/Set</p>
                                                </div>
                                            </th>
                                            <th className="bg-gradient-to-l from-[#507C37] to-[#33432A] text-white font-medium w-[100px] py-2" >
                                                <div>
                                                    <p>Weight/Set</p>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>

                        <div className="w-full mt-14" >
                            <div className="flex justify-between text-[#0054FF]" >
                                <p className="bg-gradient-to-l from-[#507C37] to-[#232323] bg-clip-text text-transparent" >Today's Food Notes</p>
                                <p className="flex items-center" >edit note <FaPen className="ml-3" /></p>
                            </div>
                            <div className="w-full h-[153px] bg-[#D9D9D9] mt-4" >

                            </div>
                        </div>
                    </div>
                    <div className="w-[25%] bg-gradient-to-l from-[#507C37] to-[#232323] text-white flex flex-col items-center justify-center min-h-[600px]" >
                        <div className="flex items-center ">
                            <img src={PremiumImg} alt="" />
                            <div className="text-[20px] ml-3" >PREMIUM</div>
                        </div>
                        <div className="text-[60px] px-5 text-center font-bold leading-[80px] drop-shadow-lg" >Hit your macro goals</div>
                        <div className="text-[18px] px-5 text-center my-5" >Find your ideal balance of carbs, fat, and protein.</div>
                        <button className="w-[270px] h-[46px] rounded-[12px] text-[#253200] bg-white font-medium" >GO PREMIUM</button>
                    </div>
                </section>

                <div className="w-[70%] flex items-center justify-center my-5" >
                    <button className="bg-gradient-to-l from-[#507C37] to-[#232323] text-white w-[250px] h-[39px] font-medium rounded-[6px] flex justify-center items-center" > <MdOutlinePrint className="text-[22px]" /> View Full Report (Printable)</button>
                </div>

                <footer className="bg-white py-10 mt-[100px]" >
                    <div className="flex items-center justify-center flex-wrap sm:w-[90%] md:w-[65%] text-[#0054FF] mx-auto" >
                        <Link to="" className="mx-2"  >Calorie Counter</Link>
                        <Link to="" className="mx-2">Blog</Link>
                        <Link to="" className="mx-2">Terms</Link>
                        <Link to="" className="mx-2">Privacy</Link>
                        <Link to="" className="mx-2">Contact Us</Link>
                        <Link to="" className="mx-2">API</Link>
                        <Link to="" className="mx-2">Jobs</Link>
                        <Link to="" className="mx-2">Feedback</Link>
                        <Link to="" className="mx-2">Community Guidelines</Link>
                        <Link to="" className="mx-2">Cookie</Link>
                        <Link to="" className="mx-2">Preferences</Link>
                        <Link to="" className="mx-2">Ad Choices</Link>
                        <Link to="" className="mx-2">Do Not Sell My Personal Information</Link>
                    </div>

                    <div className="mt-4" >
                        <p className="text-center text-[#000]" >© 2024 Live100, Inc.</p>
                    </div>

                </footer>
            </div>
        </>
    )
}

export default ExerciseDiary;