import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    PinInput,
    PinInputField,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import { ClipLoader } from "react-spinners";
import liveLogo from '../assets/live100_logo.svg';
import { forgetUserPassword, resendOtp, verifyEmail, verifyUserEmail } from 'app/auth/auth.action';
import ResetPassModal from './ResetPassModal';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
    email: string;
}

const VerifyCodeModal: React.FC<SignupModalProps> = ({ isOpen, onClose , email}) => {

    const [otp , setOtp] = useState<string>('');
    const [isResetPassModal , setIsResetPassModal] = useState<boolean>(false);

    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);

    const hanldleVerification = async () => {
        onClose();
        const result = await dispatch(verifyEmail(email , otp) as any)
        if(result?.success){
            localStorage.setItem("accesstoken" , result?.data?.accessToken)
            toast({
                description: result?.data?.message,
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
            setIsResetPassModal(true);
        }
        else{
            toast({
                description: result?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center w-full" >
                                <h1 className="text-[32px] font-semibold my-3 w-full text-center" >Check Your Email</h1>
                                <div className="flex flex-col w-[90%]" >
                                    <div className='my-2 flex flex-col items-center' >
                                        <p>We’ve sent you a verification code.</p>
                                        <HStack>
                                            <PinInput
                                                otp
                                                onChange={(value) => setOtp(value)} // Capture OTP value
                                            >
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                            </PinInput>
                                        </HStack>

                                        <Button onClick={hanldleVerification} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="8px" color="#fff" margin="13px 0" padding="13px 40px" className="font-semibold" >
                                            {
                                                AuthData.loading ?
                                                    <ClipLoader color="white" size={25} />
                                                    :
                                                    "Continue"
                                            }
                                        </Button>
                                        </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <ResetPassModal isOpen={isResetPassModal} onClose={() => setIsResetPassModal(false)} />

        </>
    );
};

export default VerifyCodeModal;
