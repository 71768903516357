import React , {useEffect, useState} from 'react'; 
import { useDispatch , useSelector } from 'react-redux';
import { GetUser } from 'app/user/user.action';
import { useNavigate } from 'react-router-dom';
import { Session } from 'app/stripe/stripe.action';
import { Box, Flex, IconButton, useBreakpointValue, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { FiMenu, FiUser } from 'react-icons/fi';
import { ClipLoader } from "react-spinners";

const NutritionNavbar = ({ onOpenSidebar }: { onOpenSidebar: () => void }) => {
    const isDesktop = useBreakpointValue({ base: false, md: true });

    const [stripeModal , setStripeModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Stripe = useSelector((state:any) => state.stripe);
    const User = useSelector((state:any) => state.user);

    const handleLogout = () => {
        localStorage.removeItem("accesstoken");
        navigate("/");
    }

    const handleBillings = async () => {
        const result = await dispatch(Session() as any);
        if(result.payload.includes('http')){
            window.location.href = result.payload
        }
    }

    useEffect(() => {
      dispatch(GetUser() as any);
    },[])
    return (
        <Flex
        as="nav"
        align="center"
        justify="space-between"
        p={4}
        bg="transparent"
        w="full"
      >
        {/* Hamburger menu to open sidebar on mobile */}
        <IconButton
          icon={<FiMenu />}
          aria-label="Open sidebar"
          display={{ base: "inline-flex", md: "none" }}
          onClick={onOpenSidebar}
        />
  
        {/* Navbar title */}
        {/* <Box fontWeight="bold" fontSize="lg">Nutrition Agent</Box> */}
  
        {/* Navigation links for desktop */}
        {isDesktop ? (
          <Flex width="100%" align="center" justifyContent="space-between" gap={4}>
            <Box>
            <Button variant="ghost" onClick={() => navigate("/user/dashboard")} color="#7B7B7B">Dashboard</Button>
            <Button variant="ghost" onClick={() => navigate("/user/food")} color="#7B7B7B">Food</Button>
            <Button variant="ghost" onClick={() => navigate("/user/exercise")} color="#7B7B7B">Exercise</Button>
            <Button variant="ghost" onClick={() => navigate("/user/wellness")} color="#7B7B7B">Wellness</Button>
            <Button variant="ghost" onClick={() => navigate("/user/community")} color="#7B7B7B">Community</Button>
            </Box>

            {/* Profile/Settings Button */}
            <Menu>
                        <MenuButton background="transparent" _focus={{ background: 'transparent' }} _hover={{ background: 'transparent' }} as={Button}>
                            <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
                                <Box width="35px" height="35px" borderRadius="50%" background="#000">

                                </Box>
                                <Box margin="0 14px" >
                                    <p className="font-semibold" >{User?.user?.userName}</p>
                                </Box>
                            </Flex>
                        </MenuButton>
                        <MenuList>
                            <MenuItem fontWeight="600" >Profile</MenuItem>
                            <MenuItem onClick={handleBillings} fontWeight="600" display="flex" alignItems="center" justifyContent="space-between" >
                            Billings
                            {
                                Stripe?.loading && <ClipLoader color="gray" size={25} />
                            }
                            </MenuItem>
                            <MenuItem onClick={handleLogout} color="red" fontWeight="600" >Logout</MenuItem>
                        </MenuList>
                    </Menu>
                        
          </Flex>
        ) : (
          // Collapsible menu for mobile
          <Menu>
            <MenuButton as={IconButton} icon={<FiMenu />} aria-label="Open menu" />
            <MenuList>
              <MenuItem>Dashboard</MenuItem>
              <MenuItem>Food</MenuItem>
              <MenuItem>Exercise</MenuItem>
              <MenuItem>Wellness</MenuItem>
              <MenuItem>Community</MenuItem>
              <MenuItem icon={<FiUser />}>Profile</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    );
  };

  export default NutritionNavbar;