import React from 'react';
import { Box, useBreakpointValue, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerBody, Button } from '@chakra-ui/react';
import { GetAllChats , CreateChat } from 'app/chats/chats.action';
import { useDispatch , useSelector } from 'react-redux';
import toast, { Toaster } from "react-hot-toast";
import Logo from '../assets/logo-with-apple.svg';
import { MdWest } from "react-icons/md";
import { MdAdd } from "react-icons/md";

const NutritionSidebar = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const Chats = useSelector((state: any) => state.chats);

  const dispatch = useDispatch();

  const handleNewChat = async () => {
    await dispatch(GetAllChats() as any)
    if(Chats?.chatList?.length > 0) {
      // const isEmptyChatExist = Chats?.chatList?.some((chat: any) => chat.conversationCount === 0);
      if (Chats?.conversation.length === 0) {
        toast.error("Empty Chat Alredy exist!")
      }
      else {
        await dispatch(CreateChat() as any);
      }
    }
    await dispatch(GetAllChats() as any)
  }
  return isDesktop ? (
    // For larger screens, show the sidebar permanently
    <Box
      as="aside"
      w="300px"
      bg="#202123"
      color="white"
      p={4}
      h="100vh"
    >
      {/* Sidebar content */}
      <Box fontWeight="bold" mb={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <img src={Logo} alt="live100-logo" className='w-[150px]' />
          <Box display="flex" alignItems="center">
            <MdWest className='mr-1' />
            Back
          </Box>
        </Box>
        <Box margin="20px 0" onClick={handleNewChat}>
          <Button display="flex" alignItems="center" justifyContent="start" fontWeight="600" borderRadius="12px" width="100%" height="59px"> <MdAdd className='mr-2' /> New chat</Button>
        </Box>
      </Box>
      {/* Add sidebar items */}
    </Box>
  ) : (
    // For smaller screens, use Chakra's Drawer component
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="gray.900" color="white">
        <DrawerCloseButton />
        <DrawerBody p={4}>
          <Box fontWeight="bold" mb={4}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <img src={Logo} alt="live100-logo" className='w-[150px]' />
              <Box display="flex" alignItems="center">
                <MdWest className='mr-1' />
                Back
              </Box>
            </Box>
            <Box margin="20px 0" onClick={handleNewChat} >
              <Button display="flex" alignItems="center" justifyContent="start" fontWeight="600" borderRadius="12px" width="100%" height="59px"> <MdAdd className='mr-2' /> New chat</Button>
            </Box>
          </Box>
          {/* Add sidebar items */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NutritionSidebar;
