import { error } from "console";
import { CHAT_ACTIONS } from "./dashboard.constants";

const INITIAL_STATE = {
    loading: false,
    success: false,
    dashboardStats : '',
    error : ''
}

export const dashboard = (state:any = INITIAL_STATE , action:any) => {
    switch(action.type) {
        case CHAT_ACTIONS.DASHBOARD_STATS_PROCESS:
            return{
                ...state,
                loading: true,
                success: false,
            }
        case CHAT_ACTIONS.DASHBOARD_STATS_SUCCESS:
            return{
                ...state,
                loading: false,
                success: true,
                dashboardStats: action.payload
            }
        case CHAT_ACTIONS.DASHBOARD_STATS_FAILED:
            return{
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
            
        default:
            return state;
    }
}