import { STRIPE_ACTIONS } from "./stripe.constants";
import { getPlans , checkoutSession , session} from "./stripe.service";
import toast, { Toaster } from "react-hot-toast";

export const GetPlans = () => {
    return async (dispatch: any) => {
      dispatch({ type: STRIPE_ACTIONS.GETTING_PLANS });
      try {
        const data: any = await getPlans();
        return dispatch({ type: STRIPE_ACTIONS.GET_PLANS , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: STRIPE_ACTIONS.GET_PLANS_FAILED,
          payload: error.message,
        });
      }
    };
  };

  export const CheckoutSession = (priceId:any) => {
    return async (dispatch: any) => {
      dispatch({ type: STRIPE_ACTIONS.SESSION_CHECKOUT_PROCESS});
      try {
        const data: any = await checkoutSession(priceId);
        return dispatch({ type: STRIPE_ACTIONS.SESSION_CHECKOUT , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: STRIPE_ACTIONS.SESSION_CHECKOUT_FAILED,
          payload: error.message,
        });
      }
    };
  };

  export const Session = () => {
    return async (dispatch: any) => {
      dispatch({ type: STRIPE_ACTIONS.SESSION_PROCESS});
      try {
        const data: any = await session();
        return dispatch({ type: STRIPE_ACTIONS.SESSION , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: STRIPE_ACTIONS.SESSION_FAILED,
          payload: error.message,
        });
      }
    };
  };