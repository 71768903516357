import React, { useEffect } from "react";
import { useLocation , useNavigate } from "react-router-dom";
import darkLogo from '../assets/dark-logo.png'
const Success = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const accessToken = queryParams.get("accessToken")
    localStorage.setItem("accesstoken" , accessToken);

    useEffect(() => {
        if(localStorage.getItem("accesstoken") && (localStorage.getItem("signup") === "true")){
            navigate("/onboard-steps")   
        }
        else if(localStorage.getItem("accesstoken")){
            navigate("/")   
        }
    },[accessToken])

    return(
        <>
        <section className="w-screen h-screen flex items-center justify-center" >
            <div className="w-[30%] h-[30%]  p-6 flex flex-col items-center justify-center" >
            <img src={darkLogo} alt="logo" />
            <h1 className="text-[30px] font-semibold" >Authetication Successfull</h1>
            </div>
        </section>
        </>
    )
}


export default Success;