import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { CHAT_ENDPOINTS } from "./dashboard.constants";
import toast, { Toaster } from "react-hot-toast";

  export const GetDashboardStats = async () => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.GET_DASHBOARD_STATS,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in getting dashboard stats!`);
      }
      return response.data;
    } catch (error) {
      console.error(`dashboard stats > ${error.toString()}`);
      throw error;
    }
  };
