import React, { ReactNode } from "react";
import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "components/Navbar";
import { Box, Container, Flex, Heading, Text, Image, VStack, HStack, useBreakpointValue, Grid, GridItem, Button, SimpleGrid, Link, Icon } from "@chakra-ui/react";
import { FaInstagram, FaFacebook, FaYoutube, FaLinkedin, FaTiktok } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { checkAutoLogin } from "app/user/user.service";
import { GetUser } from "app/user/user.action";

import PlanTableModal from "components/PlanTableModal";

// Assets
import MissionImg from '../assets/our-mission.svg';
import WellnessBg from '../assets/wellness-bg.svg';
import NutritionBg from '../assets/nutrition-bg.svg';
import FitnessBg from '../assets/fitness-bg.svg';
import Brain from '../assets/brain.svg';
import SendIcon from '../assets/Send_fill.svg';
import RightIcon from '../assets/right-icon.svg';
import RightIconWhite from '../assets/right-icon-white.svg';
import HeroImage1 from '../assets/hero-1.png';
import HeroImage2 from '../assets/hero-2.png';
import HeroImage3 from '../assets/hero-3.png';
import HeroImage4 from '../assets/hero-4.png';
import HeroImage5 from '../assets/hero-5.png';
import HeroApple from '../assets/hero-apple.png';
import { useDispatch, useSelector } from "react-redux";

interface SectionProps {
  children: ReactNode;
  [key: string]: any;
}

const Section: React.FC<SectionProps> = ({ children, ...props }) => (
  <Box as="section" my="100px" {...props}>
    {children}
  </Box>
);
interface CardProps {
  title: string;
  children: ReactNode;
}

const Card: React.FC<CardProps> = ({ title, children }) => (
  <Box
    boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
    borderRadius="14px"
    p="12px 19px"
    mb="13px"
  >
    <Heading fontSize="24px" color="#253200" mb="2" fontWeight="semibold">
      {title}
    </Heading>
    <Text>{children}</Text>
  </Box>
);

interface ResponsiveTextProps {
  children: ReactNode;
  [key: string]: any;
}

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 700,
  fade: true,
  cssEase: 'linear',
  autoplay: true,
  autoplaySpeed: 2000
};


const ResponsiveText: React.FC<ResponsiveTextProps> = ({ children, ...props }) => {
  const fontSize = useBreakpointValue({ base: "24px", md: "52px" });
  return (
    <Heading fontSize={fontSize} color="#253200" mb="2" fontWeight="semibold" {...props}>
      {children}
    </Heading>
  );
};


export const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((state:any) => state.user);

  const isSmallScreen = useBreakpointValue({ base: true, md: true, lg: true });
  const [navbarStyle, setNavbarStyle] = useState({ bgColor: 'transparent', textColor: 'white' , isDarkLogo:false });

  const navigation = async () => {
    await dispatch(GetUser() as any);
    if(User?.user?.isonboarded){
      navigate("/user/dashboard")
    }
    else if(!(User?.user?.isonboarded)) {navigate("/onboard")}
    }
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setNavbarStyle({ bgColor: 'white', textColor: '#253200' , isDarkLogo: true });
      } else {
        setNavbarStyle({ bgColor: 'transparent', textColor: '#253200' , isDarkLogo: false });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if(checkAutoLogin()){
    navigation();
  }
  },[User?.user])

  return (
    <>
  {/* ---------------------------------------- Navbar ----------------------------------------------- */}
  <Box position="fixed" top="0" left="0" right="0" zIndex="1000">
        <Navbar bgColor={navbarStyle.bgColor} textColor={navbarStyle.textColor} isDarkLogo={navbarStyle.isDarkLogo} />
      </Box>

      {/* -------------------------------------- Hero Section --------------------------------------------- */}
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        height={{ base: "auto", md: "auto", lg: "100vh" }}
        maxW="100vw"
        justifyContent="space-between"
        bgGradient="linear(to-l, #507C37, #232323)"
        overflow="hidden"
      >
        {/* Left Content */}
        <Flex
          direction="column"
          justify="center"
          align="flex-start"
          py={{ base: 10, md: 12, lg: 14 }}
          px={{ base: 6, md: 20, lg: 20 }}
          width={{ base: "100vw", lg: "40vw" }}
          backgroundImage={{ base: "none", md: "none", lg: HeroApple }} 
          backgroundSize="contain"
          backgroundPosition="bottom"
          backgroundRepeat="no-repeat"
          position="relative"
        >
          <Heading
            as="h1"
            size={{ base: 'xl', md: '2xl', lg: '3xl' }}
            fontWeight="bold"
            color="white"
            mb={4}
            zIndex="999"
            width={{ base: "100%", lg: "40vw" }}
            textShadow="2px 2px 8px rgba(0, 0, 0, 0.7)"
            pt={{ base: 16, md: 20, lg: 0 }}
          >
            Want to live a longer and healthier life?
          </Heading>
          <Button
            variant="solid"
            bg="#fff"
            _hover={{ bg: "#fff" }}
            mt={{ base: 4, lg: 6 }}
            height="74px"
            width="320px"
            onClick={() => navigate("/onboard")}
          >
            Start from here
            <img src={RightIcon} alt="right-icon" />
          </Button>
        </Flex>

        {/* Image Slider */}
        <Box
          flex="1"
          position="relative"
          overflow="hidden"
          height={{ base: '40vh', md: '100vh', lg: '100vh' }}
          width={{ base: "100vw" }}
          mt={{ base: 10, md: 12, lg: 0 }}
        >
          <Slider {...sliderSettings}>
            <Box height="100vh" width="100%" position="relative">
              <img src={HeroImage1} alt="Slide 1" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            </Box>
            <Box height="100%" width="100%" position="relative">
              <img src={HeroImage2} alt="Slide 2" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            </Box>
            <Box height="100%" width="100%" position="relative">
              <img src={HeroImage3} alt="Slide 3" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            </Box>
            <Box height="100%" width="100%" position="relative">
              <img src={HeroImage4} alt="Slide 4" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            </Box>
            <Box height="100%" width="100%" position="relative">
              <img src={HeroImage5} alt="Slide 5" style={{ width: '100%', height: '100vh', objectFit: 'cover' }} />
            </Box>
          </Slider>
        </Box>
      </Flex>

      {/* -------------------------------------- AI Agents --------------------------------------------- */}
      <Box margin="150px 0" textAlign="center">
        <VStack spacing={4}>
          <Box bg="white" rounded="lg" px={3} py={1} mx="auto">
            <Heading fontSize={{ base: "24px", md: "40px" }} color="#253200">
              <Box
                as="span"
                bgGradient="linear(to-r, #507C37, #232323)"
                padding="6px"
                margin="0 10px"
                borderRadius="md"
                color="white"
              >
                24/7
              </Box>
              Availability
            </Heading>
          </Box>
          <Box
            bgGradient="linear(to-l, #507C37, #232323)"
            bgClip="text"
          >
            <Heading fontSize={{ base: "36px", md: "62px" }} mb={2} color="transparent">
              Live100 AI Agents
            </Heading>
          </Box>
        </VStack>

        <Box display="flex" flexWrap="wrap" justifyContent="center" mt={8}>
          <Box
            maxW={{ base: "100%", md: "450px" }}
            p={4}
            m={2}
            borderRadius="12px"
            display="flex" flexDirection="column" justifyContent="center" alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center" bgImage={NutritionBg} rounded='lg' width="265px" height='265px' bgRepeat="no-repeat" bgSize="cover">
              <Image src={Brain} />
            </Box>
            <VStack align="center">
              <Heading fontSize={{ base: "24px", md: "32px" }} minH="60px" marginTop="12px" color="#253200">Nutrition AI Agent</Heading>
              <Text>The AI Nutrition Agent provides expert nutrition guidance and personalized recommendations, available anytime to answer your questions. With deep knowledge in all aspects of nutrition, it ensures professional support whenever you need it.</Text>
            </VStack>
            <Box mt={5}>
              <Button
                color="white"
                bgGradient="linear(to-l, #507C37, #2C3627)"
                _hover={{ bgGradient: "linear(to-l, #507C37, #2C3627)" }}
                rightIcon={<Image src={SendIcon} />}
                borderRadius="15px"
                width="160px"
                height="49px"
              >
                Connect
              </Button>
            </Box>
          </Box>

          <Box
            maxW={{ base: "100%", md: "450px" }}
            p={4}
            m={2}
            borderRadius="12px"
            display="flex" flexDirection="column" justifyContent="center" alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center" bgImage={FitnessBg} rounded='lg' width="265px" height='265px' bgRepeat="no-repeat" bgSize="cover">
              <Image src={Brain} />
            </Box>
            <VStack align="center">
              <Heading fontSize={{ base: "24px", md: "32px" }} minH="60px" marginTop="12px" color="#253200">Fitness AI Agent</Heading>
              <Text>The AI Fitness Agent is your go-to source for expert fitness advice, always available to answer questions, discuss workout strategies, and provide personalized suggestions. With its in-depth knowledge, it ensures constant support to help you stay on track and reach your fitness goals.       </Text>
            </VStack>
            <Box mt={5}>
              <Button
                color="white"
                bgGradient="linear(to-l, #507C37, #2C3627)"
                _hover={{ bgGradient: "linear(to-l, #507C37, #2C3627)" }}
                rightIcon={<Image src={SendIcon} />}
                borderRadius="15px"
                width="160px"
                height="49px"
              >
                Connect
              </Button>
            </Box>
          </Box>

          <Box
            maxW={{ base: "100%", md: "450px" }}
            p={4}
            m={2}
            borderRadius="12px"
            display="flex" flexDirection="column" justifyContent="center" alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center" bgImage={WellnessBg} rounded='lg' width="265px" height='265px' bgRepeat="no-repeat" bgSize="cover">
              <Image src={Brain} />
            </Box>
            <VStack align="center">
              <Heading fontSize={{ base: "24px", md: "32px" }} color="#253200" minH="60px" marginTop="12px">Wellness AI Agent</Heading>
              <Text>The AI Wellness Agent offers expert support for all your wellness needs, ready to answer questions, provide personalized advice, and suggest tailored wellness plans. With deep knowledge in mental and physical well-being, it ensures continuous guidance to help you achieve balance and overall wellness.  </Text>
            </VStack>
            <Box mt={5}>
              <Button
                color="white"
                bgGradient="linear(to-l, #507C37, #2C3627)"
                _hover={{ bgGradient: "linear(to-l, #507C37, #2C3627)" }}
                rightIcon={<Image src={SendIcon} />}
                borderRadius="15px"
                width="160px"
                height="49px"
              >
                Connect
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* ---------------------------------------- Are you ready --------------------------------------------*/}
      <Section margin="150px 0" px={{ base: "20px", md: "60px" }} bgGradient="linear(to-l, #507C37, #2C3627)" color="white" py="130px">
        <Container maxW="container.lg">
          <VStack spacing="2" align="center">
            <Heading fontSize={{ base: "24px", md: "60px" }} color="#fff" fontWeight="bold" my={3}>
              Are you ready to live longer?
            </Heading>
            <Box textAlign="center">
              <Button
                color="#000"
                bg="white"
                py={2}
                my={4}
                rightIcon={<Image src={RightIcon} />}
                width="476px"
                height="84px"
                borderRadius="11px">
                DISCOVER YOUR BASELINE HEALTH SCORE TODAY
              </Button>
            </Box>
          </VStack>
        </Container>
      </Section>

      {/* ---------------------------------------- Our Mission -------------------------------------------- */}
      <Box margin="150px 0">
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          gap={6}
          py={3}
          px={{ base: 4, md: 20 }}
          alignItems="center"
        >
          <GridItem>
            <VStack align="start" spacing={4}>
              <Heading fontSize={{ base: "36px", md: "52px" }} color="#253200">Our Mission</Heading>
              <Text fontSize={{ base: "16px", md: "20px" }}>
                Our mission at Live100.ai is to help you embrace those choices and increase your chances of living a long, healthy life. Drawing from extensive research, particularly studies on the world’s Blue Zones (regions where people live significantly longer), we focus on three main pillars: <strong>Nutrition, Physical Activity,</strong> and <strong>Mental Wellness.</strong>
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Flex justify="end">
              <Image src={MissionImg} alt="our-mission-img" maxW="100%" height="auto" />
            </Flex>
          </GridItem>
        </Grid>
      </Box>


      {/* ---------------------------------------- Individual approach -------------------------------------------- */}
      <Box marginTop="150px">
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          gap={6}
          py={3}
          px={{ base: 4, md: 20 }}
          alignItems="start"
        >
          <GridItem>
            <Section>
              <Container maxW="container.lg">
                <VStack align="start">
                  <Heading fontSize="24px" color="#253200" fontWeight="semibold">
                    Individualized Approach:
                  </Heading>
                  <ResponsiveText>Personalized Plans for Everyone</ResponsiveText>
                  <Text textAlign="start">
                    We believe in the power of an Individualized Approach: Recognizing that each person is unique and requires a personalized nutrition plan tailored to their specific needs, preferences, and health conditions. Factors such as age, gender, lifestyle, activity level, and medical history are all important considerations.
                  </Text>
                </VStack>
                <Box textAlign="start">
                  <Button
                    color="#fff"
                    bgGradient="linear(to-l, #507C37, #2C3627)"
                    _hover={{ bgGradient: "linear(to-l, #507C37, #2C3627)" }}
                    margin="14px 0"
                    px={7}
                    py={5}
                    width="395px"
                    height="84px"
                    borderRadius="11px"
                    onClick={() => navigate("/onboard")}
                    >
                    START YOUR JOURNEY NOW
                    <img src={RightIconWhite} alt="" />
                  </Button>
                </Box>
              </Container>
            </Section>
          </GridItem>
          <GridItem>
            <Section>
              <Container maxW="container.lg">
                <Flex direction="column" align="center" justify="center">
                  <Box>
                    <Card title="Personalized Plans">
                      We help you develop a personalized nutrition and wellness plan that fits your unique needs and goals.
                    </Card>
                    <Card title="Key Performance Indicators (KPIs)">
                      Together, we set up key performance indicators and future plans to track your progress.
                    </Card>
                    <Card title="Ongoing Monitoring">
                      We monitor your progress with you, providing regular check-ins and adjustments to keep you on track.
                    </Card>
                    <Card title="Motivation and Support">
                      We are here to motivate you, help you improve, and celebrate your successes along the way.
                    </Card>
                  </Box>
                </Flex>
              </Container>
            </Section>
          </GridItem>
        </Grid>
      </Box>

      {/* ---------------------------------------- Footer -------------------------------------------- */}
      <Box bg="#2a320e" color="white" py={10}>
        <Box px={{ base: 4, md: 20 }}>
          <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Box>
              <Text fontSize="2xl" fontWeight="bold">Live100</Text>
              <Text mt={2}>Live your life to fullest.</Text>
              <Button
                bg="white"
                color="black"
                mt={4}
                _hover={{ bg: "#fff" }}
                rounded="12px"
                width="198px"
                height="50px"
                onClick={() => navigate("/onboard")}
              >
                START TODAY
              </Button>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 3 }}>
              <Box>
                <Text fontWeight="bold" mb={4}>Products</Text>
                <VStack align="start">
                  <Link href="#">Exercise</Link>
                  <Link href="#">Apps</Link>
                  <Link href="#">Premium</Link>
                </VStack>
              </Box>

              <Box>
                <Text fontWeight="bold" mb={4}>Resources</Text>
                <VStack align="start">
                  <Link href="#">Blog</Link>
                  <Link href="#">Community</Link>
                  <Link href="#">Contact Us</Link>
                  <Link href="#">Support Center</Link>
                </VStack>
              </Box>

              <Box>
                <Text fontWeight="bold" mb={4}>Company</Text>
                <VStack align="start">
                  <Link href="#">About Us</Link>
                  <Link href="#">Careers</Link>
                  <Link href="#">Press</Link>
                </VStack>
              </Box>
            </SimpleGrid>
          </SimpleGrid>

          <Flex justify="center" align="center" mt={8} flexDirection={{ base: "column", md: "row" }}>
            <Text fontSize="sm" mr={4}>
              © Live100, Inc.
            </Text>
            <Flex wrap="wrap" justify="center" mt={{ base: 4, md: 0 }}>
              <Link href="#" mr={4}>Community Guidelines</Link>
              <Link href="#" mr={4}>Feedback</Link>
              <Link href="#" mr={4}>Terms</Link>
              <Link href="#" mr={4} >Privacy</Link>
              <Link href="#" mr={4} >API</Link>
              <Link href="#" mr={4} >Cookie</Link>
              <Link href="#" mr={4} >Preferences</Link>
              <Link href="#" mr={4} >Ad Choices</Link>
              <Link href="#" mr={4} >Do Not Sell My Personal Information</Link>
            </Flex>
          </Flex>

          <Flex justify="center" mt={6}>
            <HStack spacing={6}>
              <Link display="flex" alignItems="center" justifyContent="center" href="#" bg="#fff" w="30px" h="30px" rounded="50%">
                <Icon color="#2a320e" as={FaInstagram} boxSize={5} />
              </Link>
              <Link display="flex" alignItems="center" justifyContent="center" href="#" bg="#fff" p={1} rounded="50%">
                <Icon as={FaFacebook} color="#2a320e" boxSize={5} />
              </Link>
              <Link display="flex" alignItems="center" justifyContent="center" href="#" bg="#fff" p={1} rounded="50%">
                <Icon as={FaYoutube} color="#2a320e" boxSize={5} />
              </Link>
              <Link display="flex" alignItems="center" justifyContent="center" href="#" bg="#fff" p={1} rounded="50%">
                <Icon as={FaLinkedin} color="#2a320e" boxSize={5} />
              </Link>
              <Link display="flex" alignItems="center" justifyContent="center" href="#" bg="#fff" p={1} rounded="50%">
                <Icon as={FaTiktok} color="#2a320e" boxSize={5} />
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default Home