import React from "react";
import { useState } from "react";
import FitnessSidebar from "./FitnessSidebar";
import { Box, Flex, Grid, IconButton, useBreakpointValue } from '@chakra-ui/react';
import NutritionNavbar from "./NutritionNavbar";
import GreenApple from '../assets/green-apple.png';
import SoundOn from '../assets/sound-on.png';
import CopyIcon from '../assets/copy-icon.png';
import LikeIcon from '../assets/like-thumb.png';
import DislikeIcon from '../assets/dislike-thumb.png';
import { FaPlusSquare } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";


const FitnessChatsPage = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarToggle = () => setSidebarOpen(!isSidebarOpen);

    return (
        <Flex height="100vh">
            {/* Sidebar */}
            <FitnessSidebar isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />

            {/* Main content */}
            <Flex direction="column" flex="1">
                {/* Navbar */}
                <NutritionNavbar onOpenSidebar={handleSidebarToggle} />

                {/* Chat box */}
                <Box p={4} flex="1">
                    {/* user message */}
                    <Box
                        p={4}
                        flex="1"
                        borderRadius="md"
                        overflowY="auto"
                        height="70vh"
                        boxShadow="sm"
                    >
                        {/* Chat message area */}
                        <Box mb={4}>
                            <Flex justify="end">
                                <Box bgGradient="linear(to-l, #507C37, #232323)" color="#fff" p={4} borderRadius="md">
                                    Hi there! How can I assist you today?
                                </Box>
                            </Flex>
                        </Box>
                        {/* ai response */}
                    <Box mb={4} p={4} width="100%" bg="#F4F4F4" borderRadius="12px" minHeight="153px" display="flex" flexDirection="column" alignItems="start" justifyContent="center" >
                        <Box display="flex" alignItems="center" >
                            <img src={GreenApple} alt="green-apple" />
                            <p className="font-semibold" >Nutrition AI Agent</p>
                        </Box>
                            <Flex justify="start">
                                <Box  bgGradient="linear(to-l, #507C37, #232323)" bgClip="text" py={4} px={1} borderRadius="md">
                                You’re very welcome! Let me know if you need any more tips or adjustments. Good luck on your journey to healthier eating!
                                </Box>
                            </Flex>

                            <Flex justify="start" align="center">
                                <img src={SoundOn} alt="" />
                                <img src={CopyIcon} alt="" />
                                <img src={LikeIcon} alt="" />
                                <img src={DislikeIcon} alt="" />
                            </Flex>
                        </Box>
                    </Box>
                </Box>
                <Box mx={8} >
                <Box p={4} border="2px solid #202123" borderRadius="12px" height="70px" display="flex" alignItems="center" justifyContent="space-between" >
                <FaPlusSquare className="text-[30px] mr-5" />
                    <input className="w-full border-none outline-none" type="text" placeholder="Send a message" />
                    <Box bgGradient="linear(to-l, #507C37, #232323)" p={2} marginLeft="10px" borderRadius="8px" >
                    <FaPaperPlane className="text-[16px] text-white" />
                    </Box>
                </Box>
                <Box margin="5px auto" textAlign="center" width="100%" fontSize="12px" color="#7B7B7B" >Live 100 AI Chatbot may produce inaccurate information about people, places, or facts.</Box>
                </Box>

            </Flex>
        </Flex>
    );
};

export default FitnessChatsPage;
