import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    PinInput,
    PinInputField,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import { ClipLoader } from "react-spinners";
import liveLogo from '../assets/live100_logo.svg';
import { resendOtp, verifyEmail } from 'app/auth/auth.action';
import { GetUser } from 'app/user/user.action';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
    email: any;
}

const OtpModal: React.FC<SignupModalProps> = ({ isOpen, onClose, email }) => {

    const [otp, setOtp] = useState<string>('');
    const isSignedUp = localStorage.getItem("signup");

    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);

    const handleOtp = () => {
        dispatch(resendOtp(email) as any);
    }

    const handleVerify = async () => {
        try {
            const result = await dispatch(verifyEmail(email, otp) as any);

            if (result.success) {
                onClose();
                toast({
                    description: "Email Verified Successfully!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                const checking = await dispatch(GetUser() as any);
                if (checking.isonboarded) { navigate("/user/dashboard") }
                else if (isSignedUp === "true") { window.location.href = "/onboard-steps" }
                else { navigate("/onboard") }
            } else {
                toast({
                    description: result.error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (error: any) {
            toast({
                description: error.message,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        }

    }


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center w-full" >
                                <h1 className="text-[32px] font-semibold my-5 w-full text-center" >Please enter One-Time Password for verification</h1>
                                <div className="flex flex-col w-[70%]" >
                                    <div className='my-4 flex flex-col items-center' >
                                        <HStack>
                                            <PinInput
                                                otp
                                                onChange={(value) => setOtp(value)} // Capture OTP value
                                            >
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                                <PinInputField />
                                            </PinInput>
                                        </HStack>

                                        <Button onClick={handleVerify} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="8px" color="#fff" margin="23px 0" padding="13px 20px" className="font-semibold" >
                                            {
                                                AuthData.loading ?
                                                    <ClipLoader color="white" size={25} />
                                                    :
                                                    "Verify"
                                            }
                                        </Button>
                                        <small className='flex items-center' >Didn't receive the verification OPT? <p className='font-semibold ml-2 cursor-pointer' onClick={handleOtp} >Resend OTP</p> </small>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default OtpModal;
