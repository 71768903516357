import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    InputGroup,
    Input,
    InputRightElement,
} from '@chakra-ui/react';

import { ClipLoader } from "react-spinners";
import { useToast } from '@chakra-ui/react'


import liveLogo from '../assets/live100_logo.svg';
import GoogleIcon from '../assets/Google.svg';
import FbIcon from '../assets/Facebook.svg';
import AppleIcon from '../assets/Apple Logo.svg';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { fbAuth, googleAuth, signUp } from 'app/auth/auth.action';
import OtpModal from './OtpModal';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
    onLoginClick: () => void;
}

const SignupModal: React.FC<SignupModalProps> = ({ isOpen, onClose, onLoginClick }) => {

    const [show, setShow] = React.useState(false);
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isOtpOpen, setIsOtpOpen] = useState(false);
    
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);

    const handleClick = () => setShow(!show);

    const handleSignup = async () => {
        if (firstname === '' || lastname === '' || password === '' || email === '' || confirmPass === '') {
            toast({
                description: "Please enter all the details!",
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
            return;
        }

        if (password !== confirmPass) {
            toast({
                description: "Please enter correct passwords!",
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
            return;
        }

        try {
            const result = await dispatch(signUp(firstname, lastname, email, password)) as any;

            if (result.success) {
                onClose();
                toast({
                    description: "OTP has been sent to your account!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  })
                setIsOtpOpen(true);
            } else {
                onClose();
                toast({
                    description: result.error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  })
            }
        } catch (error: any) {
            onClose();
            toast({
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
        }
    };

    const handleGoogleAuth = async () => {
        try {
            const result = await dispatch(googleAuth() as any)
            if (result.startsWith('http')) {
                window.location.href = result;
        }}
        catch (error: any) {

        }
    }

    const handleFbAuth = async () => {
        try {
            const result = await dispatch(fbAuth() as any)
            if (result.startsWith('http')) {
                window.location.href = result;
        }}
        catch (error: any) {

        }
    }


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center" >
                                <h1 className="text-[32px] font-semibold my-5" >Create your Live100 account</h1>

                                <div className="flex flex-col w-[70%]" >
                                    <input type="text" placeholder='First Name' onChange={(e: any) => setFirstName(e.target.value)} className="border-[1px] border-[#E2E2E2] rounded-[8px] py-2 px-2 my-3 h-[53px]" />

                                    <input type="text" placeholder='Last Name' onChange={(e: any) => setLastname(e.target.value)} className="border-[1px] border-[#E2E2E2] rounded-[8px] py-2 px-2 my-3 h-[53px]" />

                                    <input type="email" placeholder='Email Address' onChange={(e: any) => setEmail(e.target.value)} className="border-[1px] border-[#E2E2E2] rounded-[8px] py-2 px-2 my-3 h-[53px]" />

                                    <InputGroup size='md' border="1px solid #E2E2E2" borderRadius="8px" my={3} height="53px" >
                                        <Input
                                            padding="0px 9px"
                                            type={show ? 'text' : 'password'}
                                            placeholder='Enter password'
                                            border="none"
                                            outline="none"
                                            boxShadow="none"
                                            _focusVisible={{border:"none"}}
                                            onChange={(e: any) => setPassword(e.target.value)}
                                            marginTop="5px"
                                        />
                                        <InputRightElement width='2.5rem'>
                                            <Button bg="#fff" _hover={{ bg: "#fff" }} h='1.75rem' size='sm' marginTop="6px" onClick={handleClick}>
                                                {show ? <AiFillEyeInvisible className='text-[17px]' /> : <AiFillEye className='text-[17px]' />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>

                                    <InputGroup size='md' border="1px solid #E2E2E2" borderRadius="8px" my={3} height="53px" >
                                        <Input
                                            padding="0px 9px"
                                            type={show ? 'text' : 'password'}
                                            placeholder='Confirm password'
                                            border="none"
                                            marginTop="5px"
                                            _focusVisible={{border:"none"}}
                                            onChange={(e: any) => setConfirmPass(e.target.value)}
                                        />
                                        <InputRightElement width='2.5rem'>
                                            <Button bg="#fff" _hover={{ bg: "#fff" }} h='1.75rem' size='sm' marginTop="6px" onClick={handleClick}>
                                                {show ? <AiFillEyeInvisible className='text-[17px]' /> : <AiFillEye className='text-[17px]' />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>

                                    <Button onClick={handleSignup} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="12px" marginBottom="14px" color="#fff" padding="13px 20px" className="font-semibold" height="50px" >
                                        {
                                            AuthData.loading ?
                                                <ClipLoader color="white" size={25} />
                                                :
                                                "Next"
                                        }
                                    </Button>

                                    <div className="flex flex-col text-[#000]" >
                                        <p className='my-3 text-[#000] flex items-center' >Already have account? <p onClick={onLoginClick} className="font-semibold underline cursor-pointer ml-2" >Log in</p></p>
                                        <p className='text-[#000]' >Or sign up with</p>
                                        <div className="flex items-center my-5" >
                                            <div onClick={handleGoogleAuth} className='border-2 border-[#E2E2E2] rounded-xl cursor-pointer'>
                                                {
                                                    AuthData.GLoading ?
                                                    <ClipLoader color="#8a8a8a" size={25} />
                                                    :
                                                    <img src={GoogleIcon} alt="" className='px-10 py-2' />
                                                }
                                            </div>
                                            <div onClick={handleFbAuth} className='border-2 border-[#E2E2E2] rounded-xl mx-10 cursor-pointer'>
                                                {
                                                    AuthData.FBLoading ? 
                                                    <ClipLoader color="#8a8a8a" size={25} />:
                                                    <img src={FbIcon} alt="" className='rounded-lg border-2 border-[#E2E2E2] px-10 py-2' />
                                                }
                                            </div>
                                            <div className='border-2 border-[#E2E2E2] rounded-xl cursor-pointer'>
                                                <img src={AppleIcon} alt="" className='rounded-lg border-2 border-[#E2E2E2] px-10 py-2' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='my-8' >
                                        <p className='text-[#000]' >By registering, you accept our Terms of Use and Privacy Policy</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <OtpModal isOpen={isOtpOpen} onClose={() => setIsOtpOpen(false)} email={email} />
        </>
    );
};

export default SignupModal;
