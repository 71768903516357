import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({value , pathColor , score , text , width , height , gradiant } : {value:number , pathColor:string , score:string , text:string , width:number , height:number , gradiant?:string}) => {
    return(
        <>
        <div style={{ position: 'relative', width: width, height: height }}>
                        <CircularProgressbar
                            value={value}
                            circleRatio={0.75} 
                            styles={buildStyles({
                                rotation: 1 / 2 + 1 / 8,
                                strokeLinecap: 'round',
                                trailColor: '#B9B9B9',
                                pathColor: pathColor,
                            })}
                        />

                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -10%)',
                                textAlign: 'center',
                                fontSize: '25px',
                                fontWeight: 'bold',
                            }}
                            className={`${gradiant ? gradiant : "#fff"}`}
                        >
                            <div className='text-[42px] font-bold' >{score}</div>
                            <div style={{ fontSize: '12px', fontWeight: 'normal' }}>
                                {text}
                            </div>
                        </div>
                    </div>
        </>
    )
}

export default CircularProgress;