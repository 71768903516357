import { USER_ACTIONS } from "app/user/user.constant";
import { getUser , updateProfile , updatePassword , deleteUser , getAllUser , onboarding } from "./user.service";
import toast, { Toaster } from "react-hot-toast";

export const GetUser = () => {
  return async (dispatch: any) => {
    dispatch({ type: USER_ACTIONS.GETTING_USER });
    try {
      const data: any = await getUser();
      return dispatch({ type: USER_ACTIONS.GET_USER , payload:data });
    } catch (error: any) {
      localStorage.removeItem("accesstoken")
      console.error(error.message);
    }
  };
};

export const UpdateProfile = (userName:any , fullName: any) => {
  return async (dispatch: any) => {
    try {
      const data:any = await updateProfile(userName , fullName);
      toast.success("Profile updated successfully!")
      return dispatch({ type: USER_ACTIONS.UPDATE_PROFILE , payload:data });
    } catch (error: any) {
      console.error(error.message);
      toast.error(error.response.data.error);
      return dispatch({
        type: USER_ACTIONS.UPDATE_PROFILE_FAILED,
        payload: error.message,
      });
    }
  };
};

export const UpdatePassword = (oldPassword:any, password:string) => {
  return async (dispatch: any) => {
    dispatch({ type: USER_ACTIONS.UPDATING_PASSWORD });
    try {
      const data:any = await updatePassword(password , oldPassword);
      toast.success("Password updated successfully!");
      return dispatch({ type: USER_ACTIONS.UPDATE_PASSWORD , payload:data});
    } catch (error: any) {
      console.error(error.response.data.error);
      toast.error(error.response.data.error);
      return dispatch({
        type: USER_ACTIONS.UPDATE_PASSWORD_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};

export const DeleteUser = (password:string) => {
  return async (dispatch: any) => {
    dispatch({ type: USER_ACTIONS.DELETING_USER });
    try {
      const data:any = await deleteUser(password);
      return dispatch({ type: USER_ACTIONS.DELETE_USER , payload:data });
    } catch (error: any) {
      console.error(error.message);
      return dispatch({
        type: USER_ACTIONS.DELETING_USER_FAILED,
        payload: error.message,
      });
    }
  };
};

export const GetAllUser = () => {
  return async (dispatch: any) => {
    dispatch({ type: USER_ACTIONS.GETTING_ALL_USER });
    try {
      const data: any = await getAllUser();
      return dispatch({ type: USER_ACTIONS.GET_ALL_USER , payload:data });
    } catch (error: any) {
      console.error(error.message);
    }
  };
};

export const resetUser = () =>{
  return async (dispatch: any) => {
    dispatch({ type: USER_ACTIONS.RESET_USER });
  } 
}


export const Onboarding = (onboardData:any) => {
  return async (dispatch: any) => {
    dispatch({ type: USER_ACTIONS.ONBOARDING_PROCESS });
    try {
      const data:any = await onboarding(onboardData);
      return dispatch({ type: USER_ACTIONS.ONBOARDED , payload:data});
    } catch (error: any) {
      console.error(error.response.data.error);
      toast.error(error.response.data.error);
      return dispatch({
        type: USER_ACTIONS.ONBOARDING_FAILED,
        payload: error.response.data.error,
      });
    }
  };
};