import React , { useState } from "react"
import { useNavigate } from "react-router-dom"

import TrackingImg from '../assets/onboard-tracking.svg'
import FoodAndFitImage from '../assets/onboard-food-and-fitness.svg'
import MindfullImage from '../assets/onboard-mindfull-eating.svg'
import { BsChevronRight } from "react-icons/bs";
import OnboardStepper from "./OnboardStepper";
import GoBack from "./GoBack"


const Onboard = () => {

    const [isBackOpen , setIsBackOpen] = useState(false);

    const navigate = useNavigate();
    const handleProceed = () => navigate("/onboard-steps");
    
    const handleBack = () => {
        setIsBackOpen(true)
    }

    return(
        <>
        <section className="px-20 py-20 bg-gradient-to-r from-[#232323] to-[#507C37] flex flex-col items-center justify-center text-white" >
        <h3 className="text-[24px] font-medium" >Welcome to</h3>
        <h1 className="text-[85px] font-bold" >Live100</h1>
        <h2 className="text-[32px] font-medium md:w-[45%] text-center" >We need to ask you a few questions to evaluate your baseline.</h2>

        <div className="flex items-center flex-col md:flex-row mt-5" >
        <div className="onboard-card flex flex-col items-center">
            <img src={TrackingImg} alt="tracking-image" />
            <h3 className="text-[24px] font-thin text-center my-3 min-h-[150px]" >Ready for some wins? <h3 className="text-[26px] font-medium" >Start tracking, it's easy!</h3></h3>
        </div>
        <div className="onboard-card flex flex-col items-center mx-5">
            <img src={FoodAndFitImage} alt="food-and-fitness-image" />
            <h3 className="text-[24px] font-thin text-center my-3 min-h-[150px]" >Discover the impact of <br /> your food and fitness</h3>
        </div>
        <div className="onboard-card flex flex-col items-center">
            <img src={MindfullImage} alt="mindfull-image" />
            <h3 className="text-[24px] font-thin text-center my-3 min-h-[150px]" >And make mindful eating a habit <br /> for life</h3>
        </div>
        </div>

        <div className="buttons" >
        <button onClick={handleProceed} className="flex items-center justify-center font-bold bg-white rounded-[10px] text-black w-[456px] h-[58px]" >Proceed <BsChevronRight className="ml-4" /> </button>
        <button onClick={handleBack} className="text-center w-[456px] h-[58px] font-bold" >Go Back</button>
        </div>
        </section>

        <GoBack isOpen={isBackOpen} onClose={() => setIsBackOpen(false)}/>

        </>
    )
}

export default Onboard;