import React from 'react';
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required elements
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ chartData }: { chartData: any }) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: 90, 
    rotation: 225,
    circumference: 270,
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "white",
        borderRadius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <div style={{width:"230px" , height:"230px" , margin:"auto"}} ><Doughnut data={chartData} options={options} /> </div>;
};

export default DoughnutChart;