import { AUTH_ACTIONS } from "./auth.constant";

const INITIAL_STATE = {
  userId: "",
  fullName: "",
  email: "",
  isActive: false,
  isLoggedIn: false,
  loading: false,
  Gloading: false,
  FBloading: false,
  isVerified: false,
  error: "",
  success: false,
  user:""
};

export const auth = (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AUTH_ACTIONS.REGISTERING_USER:
      return { ...state, loading: true, error: "", success: false };
    case AUTH_ACTIONS.USER_REGISTERED:
      return { ...state, loading: false, success: true, error: "",user : action.payload };
    case AUTH_ACTIONS.REGISTRATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
   
      case AUTH_ACTIONS.GOOGLE_AUTH_PROCESS:
      return { ...state, socialLoading: true, error: "", success: false };
    case AUTH_ACTIONS.GOOGLE_AUTH_SUCCESS:
      return { ...state, socailLoading: false, success: true, error: ""};
    case AUTH_ACTIONS.GOOGLE_AUTH_FAILED:
      return {
        ...state,
        socialLoading: false,
        error: action.payload,
        success: false,
      };
      case AUTH_ACTIONS.GOOGLE_AUTH_PROCESS:
      return { ...state, GLoading: true, error: "", success: false };
    case AUTH_ACTIONS.GOOGLE_AUTH_SUCCESS:
      return { ...state, GLoading: false, success: true, error: ""};
    case AUTH_ACTIONS.GOOGLE_AUTH_FAILED:
      return {
        ...state,
        GLoading: false,
        error: action.payload,
        success: false,
      };
      case AUTH_ACTIONS.FB_AUTH_PROCESS:
      return { ...state, FBLoading: true, error: "", success: false };
    case AUTH_ACTIONS.FB_AUTH_SUCCESS:
      return { ...state, FBLoading: false, success: true, error: ""};
    case AUTH_ACTIONS.FB_AUTH_FAILED:
      return {
        ...state,
        FBLoading: false,
        error: action.payload,
        success: false,
      };

    case AUTH_ACTIONS.RESENDING_OTP:
      return { ...state, loading: true, error: "", success: false };
    case AUTH_ACTIONS.OTP_SENT:
      return { ...state, loading: false, success: true, error: "" };
    case AUTH_ACTIONS.RESEND_OTP_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
      
    case AUTH_ACTIONS.LOGGING_USER:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
      };
    case AUTH_ACTIONS.LOGGEDIN_USER:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case AUTH_ACTIONS.LOGGEDIN_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };

      case AUTH_ACTIONS.VERIFYING_EMAIL:
        return {
          ...state,
          loading: true,
          error: "",
          success: false,
        };
      case AUTH_ACTIONS.EMAIL_VERIFIED:
        return {
          ...state,
          loading: false,
          isVerified: true,
          success: true,
          error: "",
        };
      case AUTH_ACTIONS.EMAIL_VERIFICATION_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: false,
        };
      case AUTH_ACTIONS.FORGETTING_PASSWORD:
      return {
        ...state,
        loading: true,
        error: "",
        success: false,
      };
    case AUTH_ACTIONS.FORGET_PASSWORD:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case AUTH_ACTIONS.FORGETTING_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
      case AUTH_ACTIONS.RESET_PASSWORD:
        return {
          ...state,
          loading: true,
          error: "",
          success: false,
        };
      case AUTH_ACTIONS.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: "",
        };
      case AUTH_ACTIONS.RESET_PASSWORD_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: false,
        };
        case AUTH_ACTIONS.RESET_STATE:
          return {
            ...state,
            loading: false,
            error: false,
            success: false,
          };
     
      default:
        return state;

  }
};
