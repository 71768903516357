import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FitnessSidebar from "./FitnessSidebar";
import { Box, Flex, Grid, IconButton, useBreakpointValue } from '@chakra-ui/react';
import NutritionNavbar from "./NutritionNavbar";
import GreenApple from '../assets/green-apple.png';
import SoundOn from '../assets/sound-on.png';
import CopyIcon from '../assets/copy-icon.png';
import LikeIcon from '../assets/like-thumb.png';
import DislikeIcon from '../assets/dislike-thumb.png';
import { FaPlusSquare } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";
import Chat from './Chat';
import '../styles/globals.css'
import MobileSiderbar from './MobileSidebar';
import Sidebar from './Sidebar';
import { useDispatch, useSelector } from "react-redux";
import { CreateChat, GetAllChats, GetAssistantUser, Conversation, setConverstions, ChatConversations } from 'app/chats/chats.action';
import AgentsChat from './AgentsChat';
import toast, { Toaster } from "react-hot-toast";
import Markdown from 'react-markdown'

  const agentNamesMap = {
    "nutrition-agent" : "Nutrition Agent",
    "coordinator-agent" : "Coordinator Agent",
    "fitness-agent" : "Fitness Agent",
    "mental-health-agent" : "Wellness Agent",
  }



const Agents = () => {
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  let isFirst = useRef(true);
  let hasInitialized = useRef(false);

  const [message, setMessage] = useState("");
  const [isConvo, setIsConvo] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileInfo, setFileInfo] = useState<{ name: string; type: string; } | null>(null);

  const [typedContent, setTypedContent] = useState("");
  const hasRun = useRef(false);


  const dispatch = useDispatch();
  const Chats = useSelector((state: any) => state.chats);
  const formData = new FormData();


  const params = useParams();
  const { chatId } = params;

  const handleSidebarToggle = () => setSidebarOpen(!isSidebarOpen);

  const toggleComponentVisibility = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleConversations(e)
      e.preventDefault();
    }
  };

  const handleConversations = async (e: any) => {
    setIsConvo(true);
    setIsLoading(true)
    const msg = message;
    setMessage("");
    const updatedConversation = [...Chats.conversation, { role: "user", content: msg }, { role: "assistant", content: "..." }];
    e.preventDefault();
    await dispatch(setConverstions(updatedConversation) as any)
    setFileInfo(null)
    setFile(null);
    await formData.append('chatId', chatId);
    await formData.append('message', msg);
    await dispatch(ChatConversations(formData) as any)
    await dispatch(Conversation(chatId) as any)
    setIsLoading(false)
    setIsConvo(false);
  }


  useEffect(() => {
    dispatch(GetAssistantUser() as any);
  }, [])

  useEffect(() => {
    dispatch(Conversation(chatId) as any)
  }, [Chats.chatList , chatId])


  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2000,
          style: {
            background: '#cdcdcd',
            color: '#fff',
          },
          success: {
            duration: 2000,
            iconTheme: {
              primary: 'green',
              secondary: 'black',
            },
          },
          error: {
            duration: 2000,
            iconTheme: {
              primary: 'red',
              secondary: 'black',
            },
          },
        }}
      />
      <Flex height="100vh">
        {/* Sidebar */}
        <FitnessSidebar isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />

        {/* Main content */}
        <Flex direction="column" flex="1">
          {/* Navbar */}
          <NutritionNavbar onOpenSidebar={handleSidebarToggle} />

          <Box p={4} flex="1">
            <Box
              p={4}
              flex="1"
              borderRadius="md"
              overflowY="scroll"
              height="70vh"
              boxShadow="sm"
              sx={{
                '::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
              }}
            >
              {
                Chats?.conversation?.map((chat: any, index: any) => {
                  return (
                    <>
                      {/* User message area */}
                      {
                        chat.role === "user" &&
                        <Box mb={4}>
                          <Flex justify="end">
                            <Box bgGradient="linear(to-l, #507C37, #232323)" color="#fff" p={4} borderRadius="md">
                              {chat.content}
                            </Box>
                          </Flex>
                        </Box>
                      }

                      {/* ai response */}
                      {
                        chat.role === "assistant" &&
                        <Box mb={4} p={4} width="100%" bg="#F4F4F4" borderRadius="12px" minHeight="153px" display="flex" flexDirection="column" alignItems="start" justifyContent="center">
                          <Box display="flex" alignItems="center" >
                            <img src={GreenApple} alt="green-apple" />
                            <p className="font-semibold ml-1" >{
                            Object.entries(agentNamesMap).map((elem:any) => elem[0] === chat.agentName && elem[1])  
                            }</p>
                          </Box>
                          <Flex justify="start">
                            <Box bgGradient="linear(to-l, #507C37, #232323)" bgClip="text" py={4} px={1} borderRadius="md">
                              {
                                index === Chats.conversation.length - 1 && Chats?.loading?
                                <>
                                 <div className="flex justify-start items-center">
                                <div className="flex space-x-2 pt-3">
                                  <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.25s]'></div>
                                  <div className='h-2 w-2 bg-gray-600 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                                  <div className='h-2 w-2 bg-gray-700 rounded-full animate-bounce'></div>
                                </div>
                              </div>
                                </>
                                :
                                <Markdown>{chat.content}</Markdown>
                              } 
                            </Box>
                          </Flex>

                          <Flex justify="start" align="center">
                            <img src={SoundOn} alt="" />
                            <img src={CopyIcon} alt="" />
                            <img src={LikeIcon} alt="" />
                            <img src={DislikeIcon} alt="" />
                          </Flex>
                        </Box>
                      }
                    </>
                  )
                })
              }
            </Box>
          </Box>


          <Box mx={8} >
            <Box p={4} border="2px solid #202123" borderRadius="12px" height="70px" display="flex" alignItems="center" justifyContent="space-between" >
              <FaPlusSquare className="text-[30px] mr-5" />
              <input className="w-full border-none outline-none" type="text" placeholder="Send a message" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeypress} />
              <Box bgGradient="linear(to-l, #507C37, #232323)" p={2} marginLeft="10px" borderRadius="8px" >
                <FaPaperPlane className="text-[16px] text-white" />
              </Box>
            </Box>
            <Box margin="5px auto" textAlign="center" width="100%" fontSize="12px" color="#7B7B7B" >Live 100 AI Chatbot may produce inaccurate information about people, places, or facts.</Box>
          </Box>

        </Flex>
      </Flex>
    </>
  )
}
export default Agents;
