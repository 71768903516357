import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import { ClipLoader } from "react-spinners";
import liveLogo from '../assets/live100_logo.svg';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const GoBack: React.FC<SignupModalProps> = ({ isOpen, onClose}) => {

    const navigate = useNavigate();
    const handleBack = () => {
        localStorage.removeItem("accesstoken");
        navigate("/");
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center w-full" >
                                <h1 className="text-[32px] font-semibold my-5 w-full text-center" >Are you sure you want to go back?</h1>
                                <div className="flex flex-col w-[70%]" >
                                    <div className='flex items-center justify-center' >
                                        <Button onClick={handleBack} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="8px" color="#fff" margin="23px 0" padding="13px 50px" className="font-semibold" >
                                            Yes
                                        </Button>
                                        <Button onClick={() => onClose()} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="8px" color="#fff" margin="23px 20px" padding="13px 50px" className="font-semibold" >
                                            No
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default GoBack;
