import React, { useEffect } from "react";
import "./styles/globals.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatPage from "./components/ChatPage";
import SignUp from "./components/Signup";
import Login from "./components/Login";
import ForgotPass from "./components/ForgotPass";
import VerifyEmail from "components/VerifyEmail";
import ResetPass from "components/ResetPass";
import ProtectedRoute from "components/protectedRoutes";
import Admin from "components/Admin";
import UserManagement from "components/UserManagement";
import FileManagement from "components/FileManagement";
import Home from "landing-page/Home";
import Success from "components/Success";
import Onboard from "components/Onboard";
import OnboardStepper from "components/OnboardStepper";
import UserDashboard from "components/UserDashboard";
import NutritionChatsPage from "components/NutritionChatsPage";
import FitnessChatsPage from "components/FitnessChatsPage";
import AgentsChatPage from "components/AgentsChatPage";
import FoodDiary from "components/FoodDiary";
import ExerciseDiary from "components/ExerciseDiary";
import AddFood from "components/AddFood";
import AddExercise from "components/AddExercise";
import Agents from "components/Agents";

function App() {
  const ProtectedDashboard = ProtectedRoute(ChatPage);
  const NutritionChatPage = ProtectedRoute(NutritionChatsPage);
  const FitnessChatPage = ProtectedRoute(FitnessChatsPage);
  const AgentsPage = ProtectedRoute(Agents);
  const ProtectedUserDashboard = ProtectedRoute(UserDashboard);
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/chat/:chatId" element={<ProtectedDashboard />} />
        <Route path="/chat" element={<ProtectedDashboard />} /> */}
        <Route path="/verify/:token" element={<VerifyEmail/>} />
        {/* <Route path="/chat/:chatId" element={<ChatPage />} />
        <Route path="/chat" element={<ChatPage />} /> */}

        {/* landing page */}
        <Route path="/" element={<Home/>} />

        {/* Auth Routes */}
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-pass" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPass />} />
        <Route path="/success" element={<Success />} />

        {/* Onboarding */}
        <Route path="/onboard" element={<Onboard />} />
        <Route path="/onboard-steps" element={<OnboardStepper />} />


         {/* Admin Routes */}
        <Route path="/admin" element={<Admin />}>
          <Route path="user-management" element={<UserManagement/>}/>
          <Route path="file-management" element={<FileManagement/>}/>
        </Route>
        
         {/* User Routes */}
        <Route path="/user">
          <Route path="dashboard" element={<ProtectedUserDashboard/>}/>
          <Route path="food" element={<FoodDiary/>}/>
          <Route path="exercise-diary" element={<ExerciseDiary/>}/>
          <Route path="add-food" element={<AddFood/>}/>
          <Route path="add-exercise" element={<AddExercise/>}/>
          <Route path="chat/:chatId" element={<AgentsPage />} />
          <Route path="chat" element={<AgentsPage />} />
        </Route>

        {/* <Route path="/ai">
        <Route path="nutrition" element={<NutritionChatPage />} />
        <Route path="fitness" element={<FitnessChatPage />} />
        <Route path="wellness" element={<WellnessChatPage />} />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
