import { AUTH_ACTIONS } from "app/auth/auth.constant";
import { registerUser, loginUser, verifyEmailService, forgetPassword, resetPassword , resendOTP, FbAuth , GoogleAuth} from "./auth.service";

export const register = (fullName: string, email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.REGISTERING_USER });
    try {
      const payload = {
        fullName,
        email,
        password,
      };
      const data = await registerUser(payload);
      dispatch({ type: AUTH_ACTIONS.USER_REGISTERED, payload: data });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.REGISTRATION_FAILED,
        payload: error.response?.data?.error || error.message,
      });
    }
  };
};

export const signUp = (firstName: string, lastName: string, email: string, password: string) => {  
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.REGISTERING_USER });
    try {
      const payload = {
        firstName,
        lastName,
        email,
        password,
      };
      const data = await registerUser(payload);
      dispatch({ type: AUTH_ACTIONS.USER_REGISTERED, payload: data });
      return { success: true, data }; 
    } catch (error: any) {
      console.error(error);
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      dispatch({
        type: AUTH_ACTIONS.REGISTRATION_FAILED,
        payload: errorMessage,
      });
      return { success: false, error: errorMessage }; 
    }
  };
};

export const resendOtp = (email: string) => {  
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.RESENDING_OTP });
    try {
      const payload = {
        email,
      };
      const data = await resendOTP(payload);
      dispatch({ type: AUTH_ACTIONS.OTP_SENT, payload: data });
      return { success: true, data }; 
    } catch (error: any) {
      console.error(error);
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      dispatch({
        type: AUTH_ACTIONS.RESEND_OTP_FAILED,
        payload: errorMessage,
      });
      return { success: false, error: errorMessage }; 
    }
  };
};


export const loginuser = (email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.LOGGING_USER });
    try {
      const payload = {
        email,
        password,
      };
      const data: any = await loginUser(payload);
      localStorage.setItem("accesstoken", data.accessToken);
      dispatch({ type: AUTH_ACTIONS.LOGGEDIN_USER });
      return data
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.LOGGEDIN_USER_FAILED,
        payload: error.response?.data?.error || error.message,
      });
      return error
    }
  };
};


export const verifyEmail = (email: string , otp:string) => {  
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.VERIFYING_EMAIL });
    try {
      const payload = {
        email,
        otp
      };
      const data = await verifyEmailService(payload);
      console.log("DAta" , data)
      localStorage.setItem("accesstoken", data.accessToken);
      dispatch({ type: AUTH_ACTIONS.EMAIL_VERIFIED, payload: data });
      return { success: true, data }; 
    } catch (error: any) {
      console.error(error);
      const errorMessage = error.response?.data?.error || "An unexpected error occurred.";
      dispatch({
        type: AUTH_ACTIONS.EMAIL_VERIFICATION_FAILED,
        payload: errorMessage,
      });
      return { success: false, error: errorMessage }; 
    }
  };
};

export const verifyUserEmail = (token: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.VERIFYING_EMAIL });
    try {
      const data = await verifyEmailService(token);
      console.log(data);

      dispatch({ type: AUTH_ACTIONS.EMAIL_VERIFIED, payload: data });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.EMAIL_VERIFICATION_FAILED,
        payload: error.message,
      });
    }
  };
};

export const forgetUserPassword = (email: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.FORGETTING_PASSWORD });
    try {
      const payload = { email };
      const result = await forgetPassword(payload);
      dispatch({ type: AUTH_ACTIONS.FORGET_PASSWORD });
      return result;
    } catch (error: any) {
      console.error(error.message);
      dispatch({
        type: AUTH_ACTIONS.FORGETTING_PASSWORD_FAILED,
        payload: error.message,
      });
      return error;
    }
  };
};

export const resetUserPassword = (password: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD });
    try {
      const token = localStorage.getItem("accesstoken");
      if (!token) {
        throw new Error("Token is missing");
      }
      const payload = { password };
      await resetPassword(payload);
      dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_SUCCESS });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.RESET_PASSWORD_FAILED,
        payload: error.message,
      });
    }
  };
};

export const resetState = () => {

  return async (dispatch: any) => {

    dispatch({ type: AUTH_ACTIONS.RESET_STATE });

  }

}


export const googleAuth = () => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.GOOGLE_AUTH_PROCESS });
    try {
      const result = await GoogleAuth();
      dispatch({ type: AUTH_ACTIONS.GOOGLE_AUTH_SUCCESS });
      return result;
    } catch (error: any) {
      console.error(error.message);
      dispatch({
        type: AUTH_ACTIONS.GOOGLE_AUTH_FAILED,
        payload: error.message,
      });
      return error;
    }
  };
};

export const fbAuth = () => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.FB_AUTH_PROCESS });
    try {
      const result = await FbAuth();
      dispatch({ type: AUTH_ACTIONS.FB_AUTH_SUCCESS });
      return result;
    } catch (error: any) {
      console.error(error.message);
      dispatch({
        type: AUTH_ACTIONS.FB_AUTH_FAILED,
        payload: error.message,
      });
      return error;
    }
  };
};

