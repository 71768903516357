export const AUTH_ACTIONS = {
  REGISTERING_USER: "REGISTERING_USER",
  USER_REGISTERED: "USER_REGISTERED",
  REGISTRATION_FAILED: "REGISTRATION_FAILED",
  LOGGING_USER: "LOGGING_USER",
  LOGGEDIN_USER: "LOGGEDIN_USER",
  LOGGEDIN_USER_FAILED: "LOGGEDIN_USER_FAILED",
  FORGETTING_PASSWORD: "FORGETTING_PASSWORD",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  FORGETTING_PASSWORD_FAILED: "FORGETTING_PASSWORD_FAILED",
  RESET_PASSWORD:"RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED:"RESET_PASSWORD_FAILED",
  VERIFYING_EMAIL: 'VERIFYING_EMAIL',
  EMAIL_VERIFIED: 'EMAIL_VERIFIED',
  EMAIL_VERIFICATION_FAILED: 'EMAIL_VERIFICATION_FAILED',
  RESET_STATE:"RESET_STATE",
  RESENDING_OTP: "RESENDING_OTP",
  OTP_SENT : "OTP_SENT",
  RESEND_OTP_FAILED: "RESEND_OTP_FAILED",
  GOOGLE_AUTH_PROCESS: "GOOGLE_AUTH_PROCESS",
  GOOGLE_AUTH_SUCCESS: "GOOGLE_AUTH_SUCCESS",
  GOOGLE_AUTH_FAILED: "GOOGLE_AUTH_FAILED",
  FB_AUTH_PROCESS: "FB_AUTH_PROCESS",
  FB_AUTH_SUCCESS: "FB_AUTH_SUCCESS",
  FB_AUTH_FAILED: "FB_AUTH_FAILED"
};

export const AUTH_ENDPOINTS = {
  REGISTER: "user/signup",
  LOGIN: "user/login",
  FORGET_PASSWORD: "user/forgot-password",
  RESET_PASSWORD:"user/reset-password",
  VERIFY_EMAIL: "user/verify-email",
  RESEND_OTP: "user/resend-otp",
  GOOGLE_AUTH: "user/google-auth",
  FB_AUTH: "user/facebook-auth"
};
