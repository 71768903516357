import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    InputGroup,
    Input,
    InputRightElement,
    Button,
    useDisclosure
} from '@chakra-ui/react';
import { Radio, RadioGroup } from '@chakra-ui/react'


interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
    weightUnits: string;
    setWeightUnits: any;
}

const WeightUnitsModal: React.FC<LoginModalProps> = ({ isOpen, onClose , weightUnits , setWeightUnits }) => {
    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="50%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" bg="#253200">
                        <div>
                        <h1 className='text-[32px] font-medium text-white' >Change Weight Units</h1>
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" color="#fff" />
                    <ModalBody width="50%" >
                        <section className="w-full h-full" >
                        <RadioGroup onChange={setWeightUnits} value={weightUnits} display="flex" flexDirection="column">
                            <Radio colorScheme='green' value='kg' name="weightUnit" margin="20px 0">
                            Kilograms
                            </Radio>
                            <Radio colorScheme='green' value='lb' name="weightUnit" margin="20px 0">
                            Pounds
                            </Radio>
                            </RadioGroup>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>

    );
};

export default WeightUnitsModal;
