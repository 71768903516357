import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    HStack,
    PinInput,
    PinInputField,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'

import { ClipLoader } from "react-spinners";
import liveLogo from '../assets/live100_logo.svg';
import { forgetUserPassword, resendOtp, verifyEmail } from 'app/auth/auth.action';
import VerifyCodeModal from './VerifyCodeModal';


interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ForgetPassModal: React.FC<SignupModalProps> = ({ isOpen, onClose}) => {

    const [email , setEmail] = useState<string>('');
    const [isVerificationModal , setIsVerificationModal] = useState<boolean>(false);

    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const AuthData = useSelector((state: any) => state.auth);

    const handleForgotPass = async () => {
        try{
            const result = await dispatch(forgetUserPassword(email) as any);
            console.log("RESULT: ", result)
            if(result){
                onClose();
                setIsVerificationModal(true);
            }
            else{
                onClose();
                toast({
                    description: result?.response?.data?.error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  })
            }
        }
        catch(error:any){
            onClose();
            toast({
                description: error?.response?.data?.error,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
        }
    }


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius="13px" maxW="780px" width="90%">
                    <ModalHeader borderBottom="2px solid #e5e5e5" >
                        <div>
                            <img src={liveLogo} alt="logo" className="w-[100px]" />
                        </div>
                    </ModalHeader>
                    <ModalCloseButton marginTop="15px" />
                    <ModalBody width="100%" >
                        <section className="w-full h-full" >
                            <div className="flex flex-col items-center w-full" >
                                <h1 className="text-[32px] font-semibold my-3 w-full text-center" >Forgot Password</h1>
                                <div className="flex flex-col w-[90%]" >
                                    <div className='my-2 flex flex-col items-center' >
                                    <input type="email" placeholder='Email Address' onChange={(e) => setEmail(e.target.value)} className="border-[2px] border-[#E2E2E2] rounded-lg py-2 px-4 my-2 w-[90%]" />
                                        <Button onClick={handleForgotPass} bgGradient="linear(to-l, #507C37, #2D3728)" _hover={{ bgGradient: "linear(to-l, #3f6f29, #1c1c1c)" }} borderRadius="8px" color="#fff" margin="13px 0" padding="13px 40px" className="font-semibold" >
                                            {
                                                AuthData.loading ?
                                                    <ClipLoader color="white" size={25} />
                                                    :
                                                    "Send"
                                            }
                                        </Button>
                                        </div>
                                </div>
                            </div>
                        </section>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <VerifyCodeModal isOpen={isVerificationModal} onClose={() => setIsVerificationModal(false)}  email={email}/>

        </>
    );
};

export default ForgetPassModal;
