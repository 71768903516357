import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyUserEmail } from "../app/auth/auth.action"; // Adjust the path as needed
import { FaRegCheckCircle } from "react-icons/fa";
import { ClipLoader } from "react-spinners"; // Import ClipLoader for loading spinner

const VerifyEmail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, success, error } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (token) {
      localStorage.setItem("accesstoken", token);
      dispatch(verifyUserEmail(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (success) {
      navigate("/user/dashboard"); 
    }
  }, [success, navigate]);

  return (
    <section className="flex items-center justify-center">
      <div className="h-screen flex flex-col items-center justify-center md:w-[30%] sm:w-[50%] w-[90%]">
        {loading && <ClipLoader size={60} color="#3b82f6" />}
        {!loading && success && (
          <>
            <FaRegCheckCircle size="60" color="#3b82f6" /> 
            <h1 className="text-2xl w-full text-center font-bold">Email Verified Successfully!</h1>
            <p className="text-sm text-center">Thank you for registering on our website.</p>
          </>
        )}
        {!loading && !success && error && (
          <>
            <h1 className="text-2xl w-full text-center font-bold">Email Verification Failed</h1>
            <p className="text-sm text-center">{error}</p>
          </>
        )}
      </div>
    </section>
  );
};

export default VerifyEmail;
